import { parseSelect } from '../parseSelect';

export const parseGraphqlSelect = <T, D extends T | undefined>(
    node: string | null | undefined,
    possibleValues: readonly T[],
    defaultValue: D,
    caseSensitive?: boolean,
): T | D => {
    return parseSelect(node, possibleValues, defaultValue, caseSensitive);
};
