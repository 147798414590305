import { useContext } from 'react';
import {
    FlagContext,
    useVariant as useVariantRaw,
    useFlag as useFlagRaw,
} from '@unleash/proxy-client-react';
import type { ExperimentCode } from './constants';

// You can consider exporting more Unleash hooks but you have to consider two issues we have:
// - The hook may be used outside of the provider so you have to return early if the context is undefined
// - We can't use the flag values for initial render unless we have a hydration error

export const useVariant = (name: ExperimentCode) => {
    const unleashContext = useContext(FlagContext);

    // Returning in a hook before an other hook is a bad practice but...
    // Unleash sdk hooks expect the context to be set and we have some situation in Astro where it's not
    // (Especially in server side only component with static content)
    // Normally, if there are no context at one render, it will never change
    // So the order and number of hooks should always remain stable
    if (!unleashContext) {
        return undefined;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const result = useVariantRaw(name);

    // Preventing a hydration error, if variant value is stored locally
    // Value would be returned at first render and mismatch server version
    if (unleashContext.client.isReady()) {
        return result;
    }

    return undefined;
};

export const useFlag = (name: ExperimentCode) => {
    const unleashContext = useContext(FlagContext);

    // Returning in a hook before an other hook is a bad practice but...
    // Unleash sdk hooks expect the context to be set and we have some situation in Astro where it's not
    // (Especially in server side only component with static content)
    // Normally, if there are no context at one render, it will never change
    // So the order and number of hooks should always remain stable
    if (!unleashContext) {
        return undefined;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const result = useFlagRaw(name);

    // Preventing a hydration error, if variant value is stored locally
    // Value would be returned at first render and mismatch server version
    if (unleashContext.client.isReady()) {
        return result;
    }

    return undefined;
};
