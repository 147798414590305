import { useMemo } from 'react';
import { defaultLocale } from '@protonme/localization';
import { addLocale, useLocale } from 'ttag';

export const useInitTtag = (locale: string, translations: string | undefined) => {
    useMemo(() => {
        if (locale === defaultLocale.code) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useLocale(locale);
        } else if (locale && translations) {
            if (typeof translations === 'object') {
                addLocale(locale, translations);
            } else {
                try {
                    addLocale(locale, JSON.parse(translations));
                } catch (e) {
                    console.error('Failed to parse ttag translations to JSON');
                    console.error(e);
                }
            }
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useLocale(locale);
        }
    }, [locale, translations]);
};
