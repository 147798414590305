import { FALLBACK_ORIGIN } from './constants';
import { getProtonAccountUrl } from './account';
import { type ProtonURL, getProtonUrl } from './getProtonUrl';
import { addLocale } from './locale';

type LinkAttributes = {
    href: string;
    target?: string;
};

type FrameworkValue = {
    currentUrl: ProtonURL;
};

type ReturnValue = {
    href: string;
    rel?: string;
    target?: string;
};

export const protonLink = (
    linkAttributes: LinkAttributes,
    { currentUrl }: FrameworkValue,
): ReturnValue => {
    const url = getProtonUrl(linkAttributes.href) || new URL(linkAttributes.href, FALLBACK_ORIGIN);

    const getLocalUrl = (url: URL) =>
        addLocale(url, currentUrl.locale).href.replace(currentUrl.origin, '');

    if (url?.protocol === 'mailto:' || url?.protocol === 'tel:') {
        return { href: url?.href };
    }

    if (url?.hostname === 'account.proton.me' || url?.hostname === 'account.protonvpn.com') {
        return {
            rel: 'noopener noreferrer',
            href: getProtonAccountUrl(url, currentUrl).href,
        };
    }

    // External link, always open in a new tab
    if (url?.hostname !== currentUrl.hostname) {
        return {
            href: url.href,
            target: '_blank',
            rel: 'noopener noreferrer',
        };
    }

    if (url?.hash !== '') {
        // check if hash is related to the current page or an other page
        // 'https://proton.me/#' is matching anchors to current page once gone through getProtonUrl() helper
        if (
            currentUrl.pathname.endsWith(url?.pathname) ||
            url?.href.startsWith(`https://${currentUrl.hostname}/#`)
        ) {
            return {
                href: url?.hash,
            };
        }
    }

    // Internal link but with explicit target _blank
    if (linkAttributes.target === '_blank') {
        return {
            href: getLocalUrl(url),
            target: '_blank',
            rel: 'noopener noreferrer',
        };
    }

    // internal link using gatsby router
    return {
        href: getLocalUrl(url),
    };
};
