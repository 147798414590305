import { useFramework } from '../../../modules/framework/useFramework';

export type ZendeskUnit = {
    type: 'BUSINESS' | 'DRIVE' | 'VPNBUSINESS';
    iframeUrl: '/zendesk/business.html' | '/zendesk/drive.html' | '/zendesk/vpn/business.html';
    widgetApperanceDelay: number;
} | null;

export const useZendeskUnit: () => ZendeskUnit = () => {
    const { currentUrl } = useFramework();

    const isProtonVpn = currentUrl.website === 'protonvpn-com';

    const isBusinessVPNUnit =
        isProtonVpn &&
        /^\/business(\/|\/?$)(?!(contact|signup)(\/|\/?$))/.test(currentUrl.pathname);

    if (isBusinessVPNUnit) {
        return {
            type: 'VPNBUSINESS',
            iframeUrl: '/zendesk/vpn/business.html',
            widgetApperanceDelay: 10000,
        };
    }

    const isBusinessUnit = /^\/business(\/|\/?$)(?!(contact|signup|security-guide)(\/|\/?$))/.test(
        currentUrl.pathname,
    );

    if (isBusinessUnit && !isProtonVpn) {
        return {
            type: 'BUSINESS',
            iframeUrl: '/zendesk/business.html',
            widgetApperanceDelay: 20000,
        };
    }

    const isDriveUnit = /^\/drive\/pricing(\/|\/?$)/.test(currentUrl.pathname);
    if (isDriveUnit) {
        return {
            type: 'DRIVE',
            iframeUrl: '/zendesk/drive.html',
            widgetApperanceDelay: 0,
        };
    }

    return null;
};
