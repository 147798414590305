import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
    checkCookie,
    CookieSameSiteAttribute,
    deleteCookie,
    getCookie,
    setCookie,
} from '../../helpers/cookies';
import { useFramework } from '../framework/useFramework';
import TuneCall from './TuneCall';
import { getOfferByAffId } from './affiliates';

export const PRIORITY_CLICK = 'P';
export const NORMAL_CLICK = 'N';
export const LOW_PRIORITY_CLICK = 'L';

interface SetNormalCookiesArguments {
    cookieDomain: string;
    visitorId?: string | null;
    hfp?: string | null;
    affiliate?: string | null;
    affiliateId?: string | null;
    offerId?: string | null | undefined;
}

interface RecordDetails {
    priority: string;
    clickTime: number;
    clickVisitorId: string;
}

export const checkDeepLink = (visitorId: string, offerId?: string | null) => {
    return offerId && !visitorId.endsWith(`-${offerId}`) ? `${visitorId}-${offerId}` : visitorId;
};

const setNormalCookies = ({
    cookieDomain,
    visitorId,
    hfp,
    affiliate,
    affiliateId,
    offerId,
}: SetNormalCookiesArguments) => {
    if (visitorId && /^[a-zA-Z0-9.@=_-]+$/.test(visitorId)) {
        const d = new Date();
        d.setTime(d.getTime() + 40 * 24 * 60 * 60 * 1000);
        const fullVisitorId = checkDeepLink(visitorId, offerId);

        setCookie({
            cookieName: 'visitorId',
            cookieValue: fullVisitorId,
            expirationDate: d.toUTCString(),
            path: '/',
            cookieDomain,
            samesite: CookieSameSiteAttribute.None,
            secure: true,
        });
        if (hfp === 'true') {
            setCookie({
                cookieName: 'offer',
                cookieValue: 'bestdeal',
                path: '/',
                cookieDomain,
                samesite: CookieSameSiteAttribute.Lax,
                secure: true,
            });
        } else if (hfp === 'false') {
            deleteCookie('offer');
        }
        if (affiliate && /^[a-zA-Z0-9]+$/.test(affiliate)) {
            setCookie({
                cookieName: 'affiliate',
                cookieValue: affiliate,
                path: '/',
                cookieDomain,
                samesite: CookieSameSiteAttribute.Lax,
                secure: true,
            });
        }
        if (affiliateId && /^[a-zA-Z0-9]+$/.test(affiliateId)) {
            setCookie({
                cookieName: 'affiliateId',
                cookieValue: affiliateId,
                expirationDate: d.toUTCString(),
                path: '/',
                cookieDomain,
                samesite: CookieSameSiteAttribute.None,
                secure: true,
            });
        }
    }
};

// check if first click from history cookie is valid by priority and expiration
export const isFirstClickPriorityValid = (cookie_value: string, priority: string) => {
    const historyRecords = getHistoryRecords(cookie_value);
    const firstDetails = getRecordDetails(historyRecords.first);
    // check if is priority the first one
    const cookiePriority = firstDetails.priority;
    const d = new Date(firstDetails.clickTime * 1000);
    const cookieExpireTime = d.setTime(d.getTime() + 40 * 24 * 60 * 60 * 1000);
    if (cookiePriority === priority && cookieExpireTime > new Date().getTime()) {
        return true;
    }
    return false;
};

// get records from history cookie
export const getHistoryRecords = (cookieValue: string) => {
    const historyCookieRecoreds = decodeURIComponent(cookieValue).split(',');
    return {
        first: historyCookieRecoreds[0] || '',
        last: historyCookieRecoreds[1] || '',
    };
};

// get record details of a click
export const getRecordDetails = (record: string): RecordDetails => {
    const recordDetails = record.split(':');
    return {
        priority: recordDetails[0] || '',
        clickTime: parseInt(recordDetails[1], 10) || 0,
        clickVisitorId: recordDetails[2] || '',
    };
};

export const getPriorityLevel = (priority: string | null): string => {
    if (priority === 'true') {
        return PRIORITY_CLICK;
    }
    if (priority === 'false') {
        return LOW_PRIORITY_CLICK;
    }
    return NORMAL_CLICK;
};

export const AffiliateCookie = (): null => {
    const { currentUrl } = useFramework();

    useEffect(
        () => {
            const urlParams = currentUrl.searchParams;
            let visitorId = urlParams.get('visitorId');
            let hfp = urlParams.get('hfp');
            let affiliate = urlParams.get('spl');
            let affiliateId = urlParams.get('aap');
            let offerId = urlParams.get('offer_id');
            const cookieDomain = `.${currentUrl.hostname}`;
            let priority = urlParams.get('p');
            const backlinking_affiliate = urlParams.get('affId');
            let clearUrl = true;
            // make last click record
            const lastPriority = getPriorityLevel(priority);
            const lastTime = new Date().getTime();
            const d = new Date();
            d.setTime(lastTime + 40 * 24 * 60 * 60 * 1000);
            const expiresLast = d.toUTCString();
            const lastVisitorId = checkDeepLink(visitorId || '', offerId);
            let lastClick = [lastPriority, lastTime, lastVisitorId].join(':');

            // in case of backlinking we overwrite visitorId, hfp and priority, and no redirect
            if (backlinking_affiliate && getOfferByAffId(backlinking_affiliate)) {
                //rewrite
                offerId = getOfferByAffId(backlinking_affiliate);
                const transactionId = [backlinking_affiliate, uuidv4().replace(/-/g, '_')].join(
                    '_',
                );
                // visitorId should be in form ho-affid_rnd32-offerId
                visitorId = ['ho', transactionId, getOfferByAffId(backlinking_affiliate)].join('-');
                lastClick = [NORMAL_CLICK, lastTime, visitorId].join(':');
                hfp = null;
                affiliate = null;
                affiliateId = null;
                priority = null;
                clearUrl = false;
                //make Tune call

                TuneCall(offerId || '', backlinking_affiliate, transactionId, currentUrl.search);
            }

            // if we recived valid visitorId we can proceed
            if (visitorId && /^[a-zA-Z0-9.@=_-]+$/.test(visitorId)) {
                const historyCookie = getCookie('history');
                // only if we still have valid priority click we just update the history cookie, and sanitize the visitorId cookie
                if (historyCookie && isFirstClickPriorityValid(historyCookie, PRIORITY_CLICK)) {
                    // update history cookie with lates click
                    const currentRecords = getHistoryRecords(historyCookie);
                    const newHistory = [currentRecords.first, lastClick]
                        .join(',')
                        .replace(/^;/g, '');
                    const winningDetails = getRecordDetails(currentRecords.first);

                    setCookie({
                        cookieName: 'history',
                        cookieValue: encodeURIComponent(newHistory),
                        expirationDate: expiresLast,
                        path: '/',
                        cookieDomain,
                        samesite: CookieSameSiteAttribute.None,
                        secure: true,
                    });

                    // check if visitor cookie is not tempered
                    if (
                        !checkCookie('visitorId', winningDetails.clickVisitorId) &&
                        winningDetails.priority === PRIORITY_CLICK
                    ) {
                        const wd = new Date();
                        wd.setTime(winningDetails.clickTime * 1000 + 40 * 24 * 60 * 60 * 1000);
                        setCookie({
                            cookieName: 'visitorId',
                            cookieValue: winningDetails.clickVisitorId,
                            expirationDate: wd.toUTCString(),
                            path: '/',
                            cookieDomain,
                            samesite: CookieSameSiteAttribute.None,
                            secure: true,
                        });
                    }
                } else if (
                    lastPriority !== LOW_PRIORITY_CLICK ||
                    !getCookie('visitorId') ||
                    isFirstClickPriorityValid(getCookie('history') || '', LOW_PRIORITY_CLICK)
                ) {
                    // we set normal cookies in is Normal or Priority (1st time),
                    // or Low priority (as 1st visit - no exsisting visitorId cookie)
                    // or Low priority as returning if it was 1st click (is in history as first click)
                    setNormalCookies({
                        cookieDomain,
                        visitorId,
                        hfp,
                        affiliate,
                        affiliateId,
                        offerId,
                    });

                    // in case of priority we should update history, or delete it only if there is nomore valid history record
                    if (lastPriority !== NORMAL_CLICK) {
                        // set the history set record for winning
                        setCookie({
                            cookieName: 'history',
                            cookieValue: encodeURIComponent(lastClick),
                            expirationDate: expiresLast,
                            path: '/',
                            cookieDomain,
                            samesite: CookieSameSiteAttribute.None,
                            secure: true,
                        });
                    } else if (
                        !(
                            isFirstClickPriorityValid(getCookie('history') || '', PRIORITY_CLICK) ||
                            isFirstClickPriorityValid(
                                getCookie('history') || '',
                                LOW_PRIORITY_CLICK,
                            )
                        )
                    ) {
                        deleteCookie('history', '/');
                    }
                }
                clearUrl && window.history.replaceState({}, document.title, currentUrl.pathname);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return null;
};
