import { useState, useCallback, useEffect } from 'react';
import type { SetCookieArguments } from '../helpers/cookies';
import { CookieSameSiteAttribute } from '../helpers/cookies';
import { getCookie, setCookie, deleteCookie as deleteCookieHelper } from '../helpers/cookies';

export const useCookie = (name: string, defaultValue?: string) => {
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        const cookie = getCookie(name);
        setValue(cookie);
    }, [name]);

    const updateCookie = useCallback(
        (newCookie: Partial<SetCookieArguments>) => {
            setValue(newCookie.cookieValue);
            setCookie({
                cookieName: name,
                path: '/',
                samesite: CookieSameSiteAttribute.None,
                secure: true,
                ...newCookie,
            });
        },
        [name],
    );

    const deleteCookie = useCallback(() => {
        setValue(undefined);
        deleteCookieHelper(name);
    }, [name]);

    return [value, updateCookie, deleteCookie] as const;
};
