import { API_HEADERS } from '../../../constants/api';

export const sendExperimentBeacon = () => {
    const { origin, state, pathname } = window.location as Location & {
        state: Record<string, string>;
    };

    const urlParams = new URLSearchParams(window.location.search);
    urlParams.append('load', 'experiment');

    const query = urlParams.toString();
    const pagePath = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;

    const prevPage = state?.prevPage ? `${origin}${state.prevPage}` : document?.referrer;

    return fetch(`${origin}${pagePath}?${query}`, {
        headers: prevPage ? { ...API_HEADERS, 'X-PM-Referer': prevPage } : API_HEADERS,
        method: 'GET',
        credentials: 'include',
    });
};
