import { useEffect } from 'react';
import { API_HEADERS } from '../../constants/api';
import { getCookie } from '../../helpers/cookies';
import { hasValue } from '../../helpers/hasValue';
import { PREFERRED_LANGUAGE_COOKIE } from '../../components/LanguageSwitcher/PreferredLanguage';
import { consumeUrlRef } from '../../components/link/useUrlWithRef';
import { useFramework } from '../framework/useFramework';

export const useAnalyticsBeacon = () => {
    const {
        currentUrl,
        routing: { state },
    } = useFramework();

    useEffect(() => {
        try {
            // Here framework url is wrong, Astro being proxified behind Apache, url is localhost
            // As this script is 100% frontend, window.location is available and correct
            const url = window.location;

            if (
                (!state || state?.prevPage !== undefined) &&
                hasValue(url.origin) &&
                hasValue(url.pathname)
            ) {
                const pagePath = url.pathname.endsWith('/')
                    ? url.pathname.slice(0, -1)
                    : url.pathname;

                const urlParams = new URLSearchParams(url.search);
                urlParams.append('load', 'ajax');

                const preferredLanguage = getCookie(PREFERRED_LANGUAGE_COOKIE);
                urlParams.append('lang', preferredLanguage || currentUrl.locale);

                const ref = consumeUrlRef();
                if (ref) {
                    urlParams.append('ref', ref);
                }

                const query = urlParams.toString();

                const prevPage = state?.prevPage
                    ? `${url.origin}${state.prevPage}`
                    : document?.referrer;

                void fetch(`${url.origin}${pagePath}?${query}`, {
                    headers: prevPage ? { ...API_HEADERS, 'X-PM-Referer': prevPage } : API_HEADERS,
                    method: 'GET',
                });
            }
        } catch (e) {
            console.error(e);
        }
    }, [state, currentUrl.locale]);
};
