import type { CSSProperties, ElementType, ReactNode } from 'react';
import { PRODUCTS } from '@protonme/apps';
import { classnames } from '../../helpers/classnames';
import { VariantProvider } from '../../context/Variant/VariantProvider';
import type { AsProps } from '../../types/as';
import './background.scss';

export const variants = ['white', 'purple-25', 'purple-800', 'purple-900', 'transparent'] as const;
export type BackgroundVariant = (typeof variants)[number];

export const gradients = ['proton', ...PRODUCTS] as const;
export type BackgroundGradient = (typeof gradients)[number];

export const horizontalPositions = ['left', 'center', 'right'];
export type HorizontalPosition = (typeof horizontalPositions)[number];

export const verticalPositions = ['top', 'center', 'bottom'];
export type VerticalPosition = (typeof verticalPositions)[number];

export const variantClasses = {
    white: 'bg-white text-purple-800',
    'purple-25': 'bg-purple-25 text-purple-800',
    'purple-800': 'bg-purple-800 text-white',
    'purple-900': 'bg-purple-900 text-white',
    transparent: undefined,
};

export type BackgroundProps = {
    /** Background color */
    variant?: BackgroundVariant;
    /** Trigger a gradient over dark backgrounds */
    gradient?: BackgroundGradient;
    /** Horizontal position of the gradient */
    posX?: HorizontalPosition;
    /** Vertical position of the gradient */
    posY?: VerticalPosition;
    /** Position override for large screens, default to ${posX} */
    lgPosX?: HorizontalPosition;
    /** Position override for large screens, default to ${posY}  */
    lgPosY?: VerticalPosition;
    /** Joker to override some gradient variables if needed
        Don't use it unless you know what you're doing */
    style?: CSSProperties;
    /** Force the background to take full space */
    full?: boolean;
    /** Optional data-testid */
    testid?: string;
    /** Content */
    children?: ReactNode;
};

export const Background = <T extends ElementType = 'div'>({
    as,
    variant = 'purple-900',
    gradient,
    posX = 'right',
    posY = 'top',
    lgPosX = posX,
    lgPosY = posY,
    style,
    full = false,
    children,
    ...rest
}: AsProps<T, BackgroundProps>) => {
    const RootTag = as || 'div';
    const variantClass = variantClasses[variant];
    const dsVariant = variant === 'purple-800' || variant === 'purple-900' ? 'dark' : 'light';

    const showGradient = dsVariant === 'dark' && !!gradient;
    const x = posX === 'center' ? 'h-center' : posX;
    const y = posY === 'center' ? 'v-center' : posY;
    const lx = lgPosX === 'center' ? 'h-center' : lgPosX;
    const ly = lgPosY === 'center' ? 'v-center' : lgPosY;

    return (
        <VariantProvider variant={dsVariant}>
            <RootTag
                className={classnames(
                    variantClass,
                    showGradient &&
                        `background-gradient-${gradient} background-gradient-${x} background-gradient-${y} lg:background-gradient-${lx} lg:background-gradient-${ly}`,
                    full && 'h-full w-full',
                )}
                style={style}
                {...rest}
            >
                {children}
            </RootTag>
        </VariantProvider>
    );
};
