import { locales } from '@protonme/localization';
import { type ProtonURL, getProtonUrl } from '@protonme/routing';
import type { FrameworkValue, NavigateFn } from './types';

export const simpleNavigateFunction: NavigateFn = async (to) => {
    if (window) {
        window.location.href = to;
    }
};

const pageBuiltInLanguages: Record<string, boolean> = {};
locales.forEach((locale) => {
    pageBuiltInLanguages[locale.code] = true;
});

export const defaultLocalizationContext = {
    alternateLanguages: [],
};

export const defaultFrameworkValue: FrameworkValue = {
    currentUrl: getProtonUrl('/') as ProtonURL,
    routing: { navigate: simpleNavigateFunction },
    localization: defaultLocalizationContext,
    unleash: undefined,
    imageCDNEnabled: false,
    cdnUrl: undefined
};
