import type { BooleanField } from '@prismicio/client';

export const parseBoolean = (
    field: BooleanField | null | undefined,
    fallback: boolean,
): boolean => {
    if (field === null || field === undefined) {
        return fallback;
    }

    return field;
};
