import { localesPme, localesVpn, protonCodeToAccountMap } from '@protonme/localization';
import { products } from './constants';
import { type ProtonURL } from './getProtonUrl';

// account url can handle all locales
const localesWithoutDefault = [...localesPme.slice(1), ...localesVpn.slice(1)];

export const isProtonAccountUrl = (url: URL) =>
    url.hostname === 'account.proton.me' || url.hostname === 'account.protonvpn.com';

export const getProductFromAccountUrlPathname = (url: URL) => {
    const product = url.pathname.split('/').find((path) => products.includes(path));
    return product || '';
};

export const getProductFromAccountUrlSearchParams = (url: URL, searchParamKey = 'product') => {
    // find product name in any param
    // if (!searchParamKey) {
    //     const searchParamValues = Array.from(url.searchParams.values());
    //     const product = searchParamValues.find((searchParamValue) =>
    //         KNOWN_PRODUCT_PARMS.filter(product => product !== "generic").includes(searchParamValue)
    //     );
    //     return product;
    // }

    const searchParamValue = url.searchParams.get(searchParamKey);
    const product = products.find((product) => product === searchParamValue);
    return product || '';
};

export const getLocaleFromAccountUrlPathname = (url: URL) => {
    const urlLocale = url.pathname.split('/')[1];
    if (Object.values(protonCodeToAccountMap).includes(urlLocale)) {
        return urlLocale;
    }
    const locale = localesWithoutDefault.find((locale) => urlLocale === locale.code);

    return locale?.code || '';
};

export const getLocaleFromAccountUrlSearchParams = (url: URL, searchParamKey = 'language') => {
    const searchParamValue = url.searchParams.get(searchParamKey);
    const locale = localesWithoutDefault.find((locale) => locale.code === searchParamValue);
    return locale?.code || '';
};

export const getProtonAccountUrl = (accountUrl: URL, currentUrl: ProtonURL) => {
    if (!isProtonAccountUrl(accountUrl)) {
        return accountUrl;
    }

    const localeInAccountUrl = getLocaleFromAccountUrlPathname(accountUrl);
    const productInAccountUrl = getProductFromAccountUrlPathname(accountUrl);

    const language =
        getLocaleFromAccountUrlSearchParams(accountUrl) ||
        localeInAccountUrl ||
        getLocaleFromAccountUrlSearchParams(currentUrl) ||
        getLocaleFromAccountUrlPathname(currentUrl) ||
        '';

    const product =
        getProductFromAccountUrlSearchParams(accountUrl) ||
        productInAccountUrl ||
        getProductFromAccountUrlSearchParams(currentUrl) ||
        getProductFromAccountUrlPathname(currentUrl) ||
        '';

    const newAccountUrl = new URL(accountUrl);

    let newPathname = (
        (language ? `/${language}` : '') +
        (product ? `/${product}` : '') +
        newAccountUrl.pathname
            .replace(localeInAccountUrl && `/${localeInAccountUrl}`, '')
            .replace(productInAccountUrl && `/${productInAccountUrl}`, '')
            .replace(/\/generic\//, '/')
            .replace(/\/generic$/, '')
            .replace(/(^$|^\/$)/, product ? '/' : '/login')
    ).replace(/\/$/, '');

    const localeToTransform = Object.keys(protonCodeToAccountMap).find(
        (code) => newPathname.split('/')[1] === code,
    );

    if (localeToTransform) {
        newPathname = newPathname.replace(
            localeToTransform,
            protonCodeToAccountMap[localeToTransform],
        );
    }

    newAccountUrl.searchParams.delete('product');
    newAccountUrl.searchParams.delete('language');

    return new URL(newAccountUrl.origin + newPathname + newAccountUrl.search);
};
