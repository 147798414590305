import { defaultLocale, localesPme, localesVpn } from '@protonme/localization';
import { downloadExtensions } from './helpers/testIsDownload';
import { type ProtonURL, getProtonUrl } from './getProtonUrl';

const testIsOfficialLocaleCode = (locale: string, protonUrl: ProtonURL) => {
    const localeCodes = (
        protonUrl.website?.includes('protonvpn-com') ? localesVpn : localesPme
    ).map((locale) => locale.code);
    return localeCodes.some((code) => code === locale);
};

export const addLocale = (
    protonUrl: URL,
    locale: string = defaultLocale.code,
    overwriteExistingLocales = false,
    forceBlogAndSupport = false,
): URL => {
    const copy = getProtonUrl(protonUrl.href);

    if (!copy) {
        return protonUrl;
    }

    // protonUrl already contains known locale
    if (copy.locale !== defaultLocale.code) {
        if (overwriteExistingLocales && copy.defaultUrl) {
            return addLocale(copy.defaultUrl, locale, overwriteExistingLocales);
        }

        return copy;
    }

    // prevent default locale from being added
    if (locale === defaultLocale.code) {
        return copy;
    }

    // prevent not official local to localize blog and support
    if (
        !forceBlogAndSupport &&
        !testIsOfficialLocaleCode(locale, copy) &&
        copy.website &&
        (copy.website?.includes('blog') || copy.website?.includes('support'))
    ) {
        return copy;
    }

    // special exception for /blog/feed not to localize
    if (copy.defaultUrl?.pathname === '/blog/feed') {
        return copy;
    }

    // special exception for download files not to localize
    if (downloadExtensions.some((ext) => copy.defaultUrl?.pathname.endsWith(ext))) {
        return copy;
    }

    // for cross links only apply supported locales
    if (!testIsOfficialLocaleCode(locale, copy)) {
        return copy;
    }

    const urlParts = copy.defaultUrl?.pathname.split('/').filter((part) => part !== '') || [];
    const newUrlParts = !(copy.website?.includes('blog') || copy.website?.includes('support'))
        ? ['', locale, ...urlParts.slice(0)]
        : ['', copy.website.includes('blog') ? 'blog' : 'support', locale, ...urlParts.slice(1)];

    copy.pathname = newUrlParts.join('/');
    return copy;
};
