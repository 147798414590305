export const downloadExtensions = [
    '.apk',
    '.exe',
    '.dmg',
    'PKGBUILD',
    '.pol',
    '.deb',
    '.rpm',
    '.txt',
    '.pdf',
    '.zip',
    '.tar.gz',
];

export const testIsDownload = (restParts: string[]) => {
    const lastPart = restParts[restParts.length - 1];
    const isDownload = lastPart
        ? downloadExtensions.some((extension) => lastPart.endsWith(extension))
        : false;

    return isDownload;
};
