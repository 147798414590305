import type { ExperimentCode, ExperimentRecord } from '../constants';
import { EXPERIMENT_CODES } from '../constants';
import { setCookie } from '../../../helpers/cookies';

export const purgeExperiments = (experiments: Record<string, string>) => {
    const purgedExperiments: ExperimentRecord = {};

    for (const k in experiments) {
        if ((EXPERIMENT_CODES as readonly string[]).includes(k)) {
            const key = k as ExperimentCode;
            purgedExperiments[key] = experiments[key];
        }
    }

    return purgedExperiments;
};

export const extractExperiments = (cookieValue: string) => {
    return cookieValue.split(',').reduce<ExperimentRecord>((acc, code) => {
        const [key, value] = code.split(':');
        acc[key as ExperimentCode] = value;
        return acc;
    }, {});
};

const experimentToCookieString = (experiments: ExperimentRecord) => {
    const experimentStringArray = Object.entries(experiments)
        .map(([experimentKey, experimentValue]) => `${experimentKey}:${experimentValue}`)
        .join(',');

    return experimentStringArray;
};

export const storeExperimentsInCookie = (experiments: ExperimentRecord) => {
    const cookieDomain = `.${window.location.hostname}`; // .proton.me/black/pink
    const cookieValue = experimentToCookieString(experiments);

    if (!cookieValue) {
        return;
    }

    setCookie({
        cookieName: 'Features',
        cookieValue,
        cookieDomain,
        path: '/',
        expirationDate: 'max',
        secure: true,
    });
};
