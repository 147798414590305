import { fetchHelper } from '../../helpers/fetchHelper';
import type {
    SlimAPI_PaymentsSubscriptionCheck,
    SlimAPI_PaymentsSubscriptionCheckRequestBody,
} from './types';

/**
 * @api /api/payments/v4/subscription/check
 * @description Proton Slim API response containing discount information for the requested PlanID/CouponCode/Cycle.
 * @docs https://protonmail.gitlab-pages.protontech.ch/Slim-API/payments/#tag/Subscriptions/operation/post_payments-v4-subscription-check
 */
export const putPaymentsSubscriptionCheck = async (
    body: SlimAPI_PaymentsSubscriptionCheckRequestBody,
) => {
    const response = await fetchHelper('/payments/v4/subscription/check', {
        method: 'PUT',
        body: JSON.stringify(body),
    });

    // in case of 4xx
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    const json: SlimAPI_PaymentsSubscriptionCheck = await response.json();
    return json;
};
