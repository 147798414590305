import type { LocaleDetail } from './locales';

export const locales: LocaleDetail[] = [
    {
        code: 'en',
        hrefLang: 'en',
        flagCode: 'en',
        prismicCode: 'en-us',
        wpCode: 'en_US',
        name: 'English',
        blog: true,
        allPagesShouldBeTranslated: true,
    },
    {
        code: 'de',
        hrefLang: 'de',
        flagCode: 'de',
        prismicCode: 'de-de',
        wpCode: 'de_DE',
        name: 'Deutsch',
        blog: false,
        allPagesShouldBeTranslated: true,
    },
    {
        code: 'fr',
        hrefLang: 'fr',
        flagCode: 'fr',
        prismicCode: 'fr-fr',
        wpCode: 'fr_FR',
        name: 'Français',
        blog: false,
        allPagesShouldBeTranslated: true,
    },
    {
        code: 'nl',
        hrefLang: 'nl',
        flagCode: 'nl',
        prismicCode: 'nl-nl',
        wpCode: 'nl_NL',
        name: 'Nederlands',
        blog: false,
        allPagesShouldBeTranslated: true,
    },
    {
        code: 'es-es',
        hrefLang: 'es-es',
        flagCode: 'es',
        prismicCode: 'es-es',
        wpCode: 'es_ES',
        name: 'Español (España)',
        blog: false,
        allPagesShouldBeTranslated: true,
    },
    {
        code: 'es-419',
        hrefLang: 'es',
        flagCode: 'mx',
        prismicCode: 'es-la',
        wpCode: 'es_LA',
        name: 'Español (Latinoamérica)',
        blog: false,
        allPagesShouldBeTranslated: true,
    },
    {
        code: 'it',
        hrefLang: 'it',
        flagCode: 'it',
        prismicCode: 'it-it',
        wpCode: 'it_IT',
        name: 'Italiano',
        blog: false,
        allPagesShouldBeTranslated: true,
    },
    {
        code: 'pl',
        hrefLang: 'pl',
        flagCode: 'pl',
        prismicCode: 'pl',
        wpCode: 'pl_PL',
        name: 'Polski',
        blog: false,
        allPagesShouldBeTranslated: true,
    },
    {
        code: 'pt-br',
        hrefLang: 'pt-br',
        flagCode: 'br',
        prismicCode: 'pt-br',
        wpCode: 'pt_BR',
        name: 'Português (Brasil)',
        blog: false,
        allPagesShouldBeTranslated: true,
    },
    {
        code: 'ru',
        hrefLang: 'ru',
        flagCode: 'ru',
        prismicCode: 'ru',
        wpCode: 'ru_RU',
        name: 'Русский',
        blog: false,
        allPagesShouldBeTranslated: true,
    },
    {
        code: 'tr',
        hrefLang: 'tr',
        flagCode: 'tr',
        prismicCode: 'tr',
        wpCode: 'tr_TR',
        name: 'Türkçe',
        blog: false,
        allPagesShouldBeTranslated: true,
    },
    {
        code: 'cs',
        hrefLang: 'cs',
        flagCode: 'cs',
        prismicCode: 'cs-cz',
        wpCode: 'cs_CZ',
        name: 'Čeština',
        blog: false,
        allPagesShouldBeTranslated: true,
    },
    {
        code: 'ko',
        hrefLang: 'ko',
        flagCode: 'ko',
        prismicCode: 'ko-kr',
        wpCode: 'ko_KR',
        name: '한국어',
        blog: false,
        allPagesShouldBeTranslated: true,
    },
    {
        code: 'ja',
        hrefLang: 'ja',
        flagCode: 'ja',
        prismicCode: 'ja-jp',
        wpCode: 'ja',
        name: '日本語',
        blog: false,
        allPagesShouldBeTranslated: true,
    },
];