import { PRODUCTS } from '@protonme/apps';
import { defaultLocale } from '@protonme/localization';

const KNOWN_PRODUCT_PARAMS = [...PRODUCTS, 'generic', 'business'];

const isTime = (time: number) => {
    return Date.now() > time ? true : false;
};

export type Cycle = 1 | 12 | 24;

type Currency = 'USD' | 'EUR' | 'GBP' | 'CHF';

type PlanSectionProps = {
    months: Cycle;
    pageType?: string;
    currency?: Currency;
    product?: string;
    selectedProduct?: string;
    enableFreeTrialFlow?: boolean;
};

type CtaHrefOptions = {
    key: string;
    locale: string;
    ref?: string;
    coupon?: string;
};

// 🦕 TODO: Move to proton-me
export const getCtaHref = (
    { pageType, months, currency, product, selectedProduct, enableFreeTrialFlow }: PlanSectionProps,
    { key, locale, ref, coupon }: CtaHrefOptions,
) => {
    const EXPERIMENT_END = Date.UTC(2023, 2, 17, 14, 0, 0);
    const isExperimentActive = !isTime(EXPERIMENT_END);

    const productParam = KNOWN_PRODUCT_PARAMS.includes(product as string)
        ? product
        : selectedProduct;

    if (
        ['Mail'].includes(pageType || '') &&
        key === 'free' &&
        enableFreeTrialFlow &&
        isExperimentActive
    ) {
        return `https://account.proton.me/${
            locale === defaultLocale.code ? '' : `${locale}/`
        }${productParam}/trial${ref ? `?ref=${ref}` : ''}`;
    }

    return `https://account.proton.me/${productParam}/signup?plan=${key}&billing=${months}&minimumCycle=${months}&currency=${currency}${
        ref ? `&ref=${ref}` : ''
    }${coupon ? `&coupon=${coupon}` : ''}`;
};
