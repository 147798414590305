import { useEffect } from 'react';
import type { ExperimentRecord } from './constants';
import { getCookie } from '../../helpers/cookies';
import { useTrafficType } from '../../hooks/useTrafficType';
import { useUADetails } from '../../hooks/useUADetails';
import { useFramework } from '../framework/useFramework';
import {
    extractExperiments,
    purgeExperiments,
    storeExperimentsInCookie,
} from './utils/experiments';
import { sendExperimentBeacon } from './utils/sendExperimentBeacon';

// globalThis is mandatory here, unless when process is undefined, it crashes
const NODE_ENV = globalThis.process?.env?.NODE_ENV;

type Props = {
    forceInTest?: boolean;
};

// Could have be a hook but needed as a component in Astro
export const UnleashStartClient = ({ forceInTest = false }: Props) => {
    const { device, browser } = useUADetails();
    const trafficType = useTrafficType();
    const { currentUrl, unleash } = useFramework();

    useEffect(() => {
        const run = async () => {
            const ignoreInTest = NODE_ENV === 'test' && !forceInTest;

            // Delay client start until we have all context data we want
            if (!unleash || ignoreInTest || !device?.type || !trafficType || browser?.is('bot')) {
                return;
            }

            // Updating Unleash context with extra context data
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            await unleash.updateContext({
                deviceType: device.type,
                trafficType,
                pagePath: currentUrl.defaultUrl.pathname,
                pageLocale: currentUrl.locale,
                websiteCode: currentUrl.website,
            } as any);

            // When Unleash client is ready, meaning flags are loaded
            // Sending a beacon to the API to track flags of each sessions (through the Feature cookie)
            unleash.on('ready', () => {
                const allFlags = unleash.getAllToggles();
                const formattedFlags = allFlags.reduce((acc, curr) => {
                    if (curr.enabled && curr.variant.payload?.value) {
                        return { ...acc, [curr.name]: curr.variant.payload.value };
                    }

                    return acc;
                }, {});

                let purgedLocalExperiments: ExperimentRecord = {};
                const featuresCookie = getCookie('Features');

                if (featuresCookie) {
                    const localExperiments = extractExperiments(featuresCookie);
                    purgedLocalExperiments = purgeExperiments(localExperiments);
                }

                storeExperimentsInCookie({
                    ...purgedLocalExperiments,
                    ...formattedFlags,
                });

                void sendExperimentBeacon();
            });

            await unleash.start();
        };

        void run();

        return () => {
            unleash?.stop();
        };
    }, [unleash, currentUrl, device?.type, trafficType, browser, forceInTest]);

    return null;
};
