import { type ProtonURL, getProtonUrl, getRelativePath } from '@protonme/routing';
import { atom } from 'nanostores';
import { defaultFrameworkValue } from '../default';
import type { FrameworkInputValue, FrameworkValue } from '../types';
import { initFrameworkClient } from './initFramework';

let globalFrameworkValue: FrameworkValue | undefined = undefined;

// Running logic at script load is not a super satisfying
// But it's the only consistent way we found to initialize the store with the context on the client side
// Ensuring that any islands (therefore React components) loads first with the default context causing hydration issues and CLS
if (globalThis.window && (globalThis.window as any).frameworkContext) {
    globalFrameworkValue = initFrameworkClient((globalThis.window as any).frameworkContext);
}

export const $frameworkStore = atom<FrameworkValue>(globalFrameworkValue || defaultFrameworkValue);

export const setFrameworkStore = (value: FrameworkInputValue) => {
    const currentUrl = getProtonUrl(getRelativePath(value.currentUrl)) as ProtonURL;
    $frameworkStore.set({ ...value, currentUrl });
};
