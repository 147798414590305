import type { Breakpoint, ImageData } from '@protonme/ui/types/image';
import { getMaxedDimensions } from '@protonme/ui/helpers/imageHelpers';

import type { GraphqlPrismicImage } from '../../types/graphql';

export const parseGraphqlImage = (
    node: GraphqlPrismicImage | null | undefined,
    maxWidth: number | Breakpoint = '2xl',
): ImageData | undefined => {
    if (!node?.url || !node.dimensions?.width || !node.dimensions?.height) {
        return undefined;
    }

    const dimensions = getMaxedDimensions(
        node.dimensions as { width: number; height: number },
        maxWidth,
    );

    return {
        src: node.url,
        ...dimensions,
        alt: node.alt || '',
        provider: 'prismic',
    };
};
