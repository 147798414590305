import type { IConfig } from 'unleash-proxy-client';
import { API_HEADERS } from '../../../constants/api';

const fetchWithCreds = (
    input: Parameters<typeof fetch>['0'],
    init: Parameters<typeof fetch>['1'],
) => {
    return fetch(input, { ...init, credentials: 'include' });
};

export const buildConfig = (api: string): IConfig => ({
    url: `${api}/feature/v2/frontend`,
    clientKey: '-', // set by the server
    appName: '-', // set by the server
    refreshInterval: 600, // refreshInterval in seconds, 10 mins
    customHeaders: API_HEADERS,
    disableMetrics: true,
    fetch: fetchWithCreds,
});
