import { type ProtonURL, addLocale } from '@protonme/routing';
import type { NavigateFn } from './types';

export const changeCurrentLocale = (
    currentUrl: ProtonURL,
    navigate: NavigateFn,
    locale: string,
    ref?: string,
) => {
    if (currentUrl.isPreview) {
        return;
    }

    const newUrl = addLocale(currentUrl, locale, true);
    const href = newUrl.href.replace(newUrl.origin, '');
    void navigate(href, { state: { ref } });
};
