import type types from 'real-ttag';
import ttag from './ttag';

export { init } from './init';

/*
 * Really really ugly stuff going on here!
 * ttag is bundled with commonjs but Vite accept it only with this import: `import * as ttag from 'ttag'`
 * (beware, the error with Vite only appears in built version)
 * but if we do that way, ttag code parser fails to extract translations
 * to leverage that issue, we create a ttag alias in Vite config pointing to that file
 * it imports the original ttag the right way for vite and export it the way it works for extractions
 */

export type StringWithRawData = types.StringWithRawData;
export type Headers = types.Headers;
export type Translations = types.Translations;
export type LocaleData = types.LocaleData;

export const t = ttag.t as typeof types.t;
export const jt = ttag.jt as typeof types.jt;
export const msgid = ttag.msgid as typeof types.msgid;
export const gettext = ttag.gettext as typeof types.gettext;
export const ngettext = ttag.ngettext as typeof types.ngettext;
export const addLocale = ttag.addLocale as typeof types.addLocale;
export const useLocale = ttag.useLocale as typeof types.useLocale;
export const setDedent = ttag.setDedent as typeof types.setDedent;
export const setDefaultLang = ttag.setDefaultLang as typeof types.setDefaultLang;
export const useLocales = ttag.useLocales as typeof types.useLocales;
export const c = ttag.c as typeof types.c;
