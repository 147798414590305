// Some recomandations:
// - Always use one of those 2 lists instead of a copy, we want to be able to add a product easily without scanning the whole code base
// - Type props and content list with official product types, that way if we add a product, TS will warn us needed content
// - Think twice about using all existing products (like for components api) or all published products (for visible enumerations)
// - "Business" is not a product, it has been consisdered, half assumed at some point, then removed back once and for all
//   It can be useful and practical in some places, but add it yourself locally, not here
// - You will need extensions: adding "none", "business", "bridge", "scribe" or anything that comes up next
//   At this point, none of them ever justified to extend the root shared lists here, just create your own extensions locally
//   Yet, always use this list and extend, that way, you will benefit from products evolutions
//   Ex: `const myProducts = ['none', ...PRODUCTS, 'bridge'];`

// All implemented products including potentially not public product yet
export const PRODUCTS = ['mail', 'calendar', 'drive', 'vpn', 'pass', 'wallet'] as const;

export type Product = (typeof PRODUCTS)[number];

// All published products, same as full list most of the time but allow to prepare products before publishing
export const PUBLISHED_PRODUCTS = ['mail', 'calendar', 'drive', 'vpn', 'pass', 'wallet'] as const;

export type PublishedProduct = (typeof PUBLISHED_PRODUCTS)[number];

export const PRODUCT_LABELS: Record<Product, string> = {
    mail: 'Proton Mail',
    calendar: 'Proton Calendar',
    drive: 'Proton Drive',
    vpn: 'Proton VPN',
    pass: 'Proton Pass',
    wallet: 'Proton Wallet',
} as const;
