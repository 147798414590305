import type { ReactNode } from 'react';
import { FlagProvider } from '@unleash/proxy-client-react';
import { useFramework } from '../framework/useFramework';

type Props = {
    children: ReactNode;
};

export const UnleashProvider = ({ children }: Props) => {
    const { unleash } = useFramework();

    return (
        <FlagProvider unleashClient={unleash} startClient={false}>
            {children}
        </FlagProvider>
    );
};
