import { parseSelect } from '@protonme/prismic';
import type { BackgroundVariant } from '@protonme/ui/components/Background/Background';
import { variants, gradients } from '@protonme/ui/components/Background/Background';
import type { VariantType } from '@protonme/ui/types/variant';

export const allKnownBackgroundNames = [
    // Those are the official ones
    ...variants,

    // Those are the legacies
    'alternative',
    'contrast',
    'dark purple',
    'dark purple (800)',
    'dark purple (800-white)',
    'dark purple gradient',
    'gradient',
    'gradient (legacy)',
    'highlight',
    'none',
    'purple',
    'purple-500',
    'purple to white vertical gradient',
    'purple-500 to dark purple vertical gradient',
    'standard',
    'white to purple vertical gradient',
] as const;

export type KnownBackgroundName = (typeof allKnownBackgroundNames)[number];

export const variantMapping: Record<BackgroundVariant, KnownBackgroundName[]> = {
    white: ['white', 'standard'],
    'purple-25': ['purple-25', 'purple', 'alternative'],
    'purple-800': ['purple-800', 'dark purple (800)', 'highlight'],
    'purple-900': ['purple-900', 'dark purple', 'contrast', 'dark purple gradient'],
    transparent: ['transparent', 'none', 'gradient', 'gradient (legacy)'],
};

const darkVariants = ['purple-800', 'purple-900'];

export const getBackgroundVariant = (
    backgroundName: string | undefined,
    defaultVariant: BackgroundVariant = 'transparent',
): BackgroundVariant => {
    if (!backgroundName) {
        return defaultVariant;
    }

    for (const variant of variants) {
        if ((variantMapping[variant] as string[]).includes(backgroundName.toLowerCase())) {
            return variant;
        }
    }

    return defaultVariant;
};

export const getVariant = (variant: BackgroundVariant | undefined): VariantType => {
    if (!variant) {
        return 'light';
    }
    return darkVariants.includes(variant) ? 'dark' : 'light';
};

const backgroundClassesMapping: Record<KnownBackgroundName, string> = {
    white: 'bg-white',
    'purple-25': 'bg-purple-25',
    'purple-500': 'bg-purple-500',
    'purple-800': 'bg-purple-800',
    'purple-900': 'bg-purple-900',
    transparent: '',
    purple: 'bg-purple-25',
    alternative: 'bg-purple-25',
    contrast: 'bg-purple-900',
    'dark purple': 'bg-purple-900',
    'dark purple (800)': 'bg-purple-800',
    'dark purple (800-white)': 'bg-[#251F47]',
    'dark purple gradient': 'bg-gradient-to-t from-purple-900 to-purple-800',
    gradient: '',
    'gradient (legacy)': '',
    highlight: 'bg-purple-800',
    none: '',
    'purple to white vertical gradient': 'bg-gradient-to-b from-purple-25 to-white',
    'purple-500 to dark purple vertical gradient': 'bg-gradient-to-b from-purple-500 to-purple-900',
    standard: 'bg-white',
    'white to purple vertical gradient': 'bg-gradient-to-b from-white to-purple-25',
};

/**
 * @deprecated Use <Background> instead
 */
export const getLegacyBackgroundClasses = (
    backgroundName: string | undefined,
): string | undefined => {
    if (!backgroundName) {
        return;
    }

    if (backgroundClassesMapping[backgroundName.toLowerCase() as KnownBackgroundName]) {
        return backgroundClassesMapping[backgroundName.toLowerCase() as KnownBackgroundName];
    }
};

/**
 * @deprecated Use <Background> instead
 */
export const getLegacyTextClasses = (backgroundName: string | undefined): string | undefined => {
    if (getVariant(getBackgroundVariant(backgroundName)) === 'dark') {
        return 'text-white';
    } else {
        return 'text-purple-800';
    }
};

/**
 * @deprecated Use parseBackground instead
 */
export const parseLegacyBackground = (value: string | null | undefined): KnownBackgroundName => {
    return parseSelect(value, allKnownBackgroundNames, 'white');
};

export const parseBackground = (
    value: string | null | undefined,
    defaultValue: BackgroundVariant = 'white',
): BackgroundVariant => {
    const parsedValue = parseSelect(value, allKnownBackgroundNames, defaultValue);
    return getBackgroundVariant(parsedValue);
};

export const getBackgroundGradient = (gradient?: string, fallback = '') => {
    if (!gradient) {
        return fallback;
    }

    if ((gradients as readonly string[]).includes(gradient.toLowerCase())) {
        return `background-gradient-${gradient.toLowerCase()}`;
    }

    return fallback;
};
