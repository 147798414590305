import { FALLBACK_ORIGIN } from '../constants';

/**
 * Helper to sanitize Prismic URLs.
 * Prismic might pass "https:///foo/bar" instead of "/foo/bar".
 * This helper can be removed once we go away from Prismic and are sure we get clean URLs.
 */
export const getSanitizedUrl = (url: URL | Location | string): URL => {
    let sanitizedUrl: URL;

    if (typeof url === 'string') {
        // catch http(s):/// hrefs (Prismic might return https:///foo/bar)
        const isBadFormattedPrismicLink = /^http(s?):\/\/\//g.test(url);
        if (isBadFormattedPrismicLink) {
            sanitizedUrl = new URL(url.substring(url.indexOf('//') + 2), FALLBACK_ORIGIN);
        } else {
            sanitizedUrl = new URL(url, FALLBACK_ORIGIN);
        }
    } else {
        sanitizedUrl = new URL(url.href, FALLBACK_ORIGIN);
    }

    // replace www. from proton.me and protonvpn.com
    if (
        sanitizedUrl.hostname === 'www.proton.me' ||
        sanitizedUrl.hostname === 'www.protonvpn.com'
    ) {
        sanitizedUrl = new URL(sanitizedUrl.href.replace('www.', ''));
    }

    // force https but don't overwrite alternative protocols (e.g. mailto:)
    sanitizedUrl.protocol = sanitizedUrl.protocol === 'http:' ? 'https:' : sanitizedUrl.protocol;

    return sanitizedUrl;
};
