/**
 * Constants and helpers for affiliates
 */

const offerId_affiliates = {
    '25': [
        '1003',
        '1005',
        '1027',
        '1046',
        '1048',
        '1083',
        '1156',
        '1191',
        '1255',
        '1338',
        '1343',
        '1454',
        '1458',
        '1653',
        '2076',
        '2146',
        '2148',
        '2381',
        '2405',
        '2442',
        '2571',
        '2620',
        '2697',
        '3090',
        '3147',
    ],
    '26': [
        '1003',
        '1005',
        '1008',
        '1010',
        '1011',
        '1022',
        '1024',
        '1026',
        '1027',
        '1028',
        '1030',
        '1032',
        '1040',
        '1041',
        '1046',
        '1048',
        '1055',
        '1056',
        '1057',
        '1064',
        '1074',
        '1076',
        '1077',
        '1078',
        '1083',
        '1086',
        '1089',
        '1101',
        '1102',
        '1103',
        '1105',
        '1113',
        '1117',
        '1118',
        '1125',
        '1128',
        '1146',
        '1147',
        '1154',
        '1156',
        '1159',
        '1162',
        '1166',
        '1173',
        '1182',
        '1191',
        '1194',
        '1197',
        '1201',
        '1207',
        '1219',
        '1223',
        '1230',
        '1235',
        '1237',
        '1241',
        '1252',
        '1255',
        '1273',
        '1274',
        '1281',
        '1282',
        '1315',
        '1329',
        '1338',
        '1341',
        '1352',
        '1356',
        '1361',
        '1391',
        '1397',
        '1400',
        '1403',
        '1408',
        '1412',
        '1427',
        '1448',
        '1454',
        '1458',
        '1462',
        '1465',
        '1472',
        '1485',
        '1492',
        '1493',
        '1498',
        '1519',
        '1533',
        '1537',
        '1541',
        '1555',
        '1561',
        '1584',
        '1588',
        '1597',
        '1603',
        '1612',
        '1625',
        '1628',
        '1637',
        '1653',
        '1655',
        '1671',
        '1673',
        '1674',
        '1679',
        '1690',
        '1698',
        '1700',
        '1701',
        '1713',
        '1720',
        '1725',
        '1733',
        '1768',
        '1804',
        '1811',
        '1819',
        '1821',
        '1824',
        '1825',
        '1835',
        '1843',
        '1847',
        '1851',
        '1855',
        '1903',
        '1912',
        '1918',
        '1924',
        '1928',
        '1939',
        '1942',
        '1945',
        '1955',
        '1960',
        '1961',
        '1965',
        '1975',
        '1991',
        '2000',
        '2001',
        '2005',
        '2014',
        '2027',
        '2032',
        '2033',
        '2076',
        '2079',
        '2083',
        '2093',
        '2096',
        '2101',
        '2105',
        '2107',
        '2111',
        '2115',
        '2118',
        '2136',
        '2137',
        '2138',
        '2146',
        '2148',
        '2155',
        '2159',
        '2172',
        '2187',
        '2193',
        '2203',
        '2205',
        '2211',
        '2225',
        '2271',
        '2272',
        '2295',
        '2299',
        '2306',
        '2307',
        '2313',
        '2323',
        '2327',
        '2329',
        '2337',
        '2352',
        '2353',
        '2354',
        '2355',
        '2357',
        '2370',
        '2381',
        '2387',
        '2395',
        '2396',
        '2407',
        '2408',
        '2417',
        '2419',
        '2423',
        '2433',
        '2442',
        '2447',
        '2451',
        '2453',
        '2458',
        '2459',
        '2464',
        '2474',
        '2480',
        '2481',
        '2484',
        '2491',
        '2493',
        '2495',
        '2502',
        '2503',
        '2513',
        '2514',
        '2517',
        '2524',
        '2527',
        '2531',
        '2535',
        '2539',
        '2555',
        '2558',
        '2560',
        '2568',
        '2571',
        '2590',
        '2599',
        '2601',
        '2602',
        '2605',
        '2619',
        '2620',
        '2621',
        '2630',
        '2640',
        '2641',
        '2642',
        '2652',
        '2655',
        '2677',
        '2685',
        '2690',
        '2691',
        '2697',
        '2700',
        '2711',
        '2712',
        '2722',
        '2727',
        '2731',
        '2739',
        '2744',
        '2745',
        '2753',
        '2770',
        '2780',
        '2788',
        '2799',
        '2802',
        '2804',
        '2805',
        '2815',
        '2835',
        '2838',
        '2841',
        '2846',
        '2855',
        '2857',
        '2859',
        '2869',
        '2871',
        '2882',
        '2892',
        '2898',
        '2905',
        '2908',
        '2913',
        '2916',
        '2920',
        '2921',
        '2928',
        '2929',
        '2934',
        '2935',
        '2936',
        '2937',
        '2948',
        '2950',
        '2951',
        '2955',
        '2956',
        '2966',
        '2976',
        '2978',
        '2979',
        '2982',
        '2983',
        '2989',
        '2995',
        '2999',
        '3006',
        '3010',
        '3023',
        '3040',
        '3045',
        '3056',
        '3060',
        '3078',
        '3092',
        '3095',
        '3108',
        '3110',
        '3113',
        '3122',
        '3140',
        '3146',
        '3147',
        '3153',
        '3164',
        '3165',
        '3169',
        '3171',
        '3179',
        '3189',
        '3191',
        '3194',
        '3195',
        '3200',
        '3205',
        '3218',
        '3230',
        '3235',
        '3236',
        '3241',
        '3244',
        '3256',
        '3267',
        '3280',
        '3297',
        '3317',
        '3415',
        '3424',
        '3447',
        '3452',
        '3464',
        '3469',
        '3481',
        '3503',
        '3509',
        '3524',
        '3531',
        '3533',
        '3560',
        '3616',
        '3649',
        '3746',
        '3768',
        '3774',
        '3816',
        '3822',
        '3825',
        '3830',
        '3908',
        '3934',
        '3971',
        '4039',
        '4052',
        '4059',
        '4161',
        '4172',
        '4192',
    ],
};

const offerId_urlId: { [key: string]: string } = {
    '25': '530',
    '26': '529',
};

export const getOfferByAffId = (affiliateId?: string) => {
    for (const [key, value] of Object.entries(offerId_affiliates)) {
        if (value.includes(affiliateId || '')) {
            return key;
        }
    }
    return null;
};

export const getUrlIdByOfferId = (offerId: string | null) => {
    return offerId && offerId in offerId_urlId ? offerId_urlId[offerId] : null;
};
