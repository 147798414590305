import { locales, protonCodeToAccountMap } from './locales';
import type { LocaleDetail } from './locales';

export const defaultLocale = locales[0];
export const localesWithoutDefault = locales.slice(1);
export const notOfficialLocales = locales.filter((locale) => !locale.allPagesShouldBeTranslated);

export const localeCodes = locales.map((locale) => locale.code);
export const localeWithoutDefaultCodes = localesWithoutDefault.map((locale) => locale.code);
export const notOfficialLocaleCodes = notOfficialLocales.map((locale) => locale.code);

export const isLocaleCode = (code: string | null | undefined) => localeCodes.includes(code || '');
export const isLocaleWithoutDefaultCode = (code: string | null | undefined) =>
    localeWithoutDefaultCodes.includes(code || '');
export const isNotOfficialLocaleCode = (code: string | null | undefined) =>
    notOfficialLocaleCodes.includes(code || '');

const mapString = (key: keyof LocaleDetail, value: keyof LocaleDetail): Record<string, string> =>
    locales.reduce<Record<string, string>>((acc, locale) => {
        acc[locale[key] as string] = locale[value] as string;
        return acc;
    }, {});

const prismicToProtonMap = mapString('prismicCode', 'code');
const protonToPrismicMap = mapString('code', 'prismicCode');
const wordpressToProtonMap = mapString('wpCode', 'code');
const protonToWordpressMap = mapString('code', 'wpCode');
const protonToNameMap = mapString('code', 'name');
const protonToHrefLangMap = mapString('code', 'hrefLang');

export const prismicToProton = (prismicCode: string): string | undefined =>
    prismicToProtonMap[prismicCode];
export const protonToPrismic = (code: string): string | undefined => protonToPrismicMap[code];
export const wordpressToProton = (wpCode: string): string | undefined =>
    wordpressToProtonMap[wpCode];
export const protonToWordpress = (code: string): string | undefined => protonToWordpressMap[code];
export const protonToName = (code: string): string | undefined => protonToNameMap[code];
export const protonToHrefLang = (code: string): string | undefined => protonToHrefLangMap[code];
export const protonToAccount = (code: string): string => protonCodeToAccountMap[code] || code;

const mapBoolean = (key: keyof LocaleDetail, value: keyof LocaleDetail): Record<string, boolean> =>
    locales.reduce<Record<string, boolean>>((acc, locale) => {
        acc[locale[key] as string] = locale[value] as boolean;
        return acc;
    }, {});

const isBlogMap = mapBoolean('code', 'blog');
const isAllPagesShouldBeTranslatedMap = mapBoolean('code', 'allPagesShouldBeTranslated');

export const isBlog = (code: string) => isBlogMap[code] || false;
export const isAllPagesShouldBeTranslated = (code: string) =>
    isAllPagesShouldBeTranslatedMap[code] || false;
