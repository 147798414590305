/**
 * Doesn't do much except nullity checks
 * But as Prismic default is to create an empty initial object, nullity check can be important
 */
export const parseGraphqlItems = <T extends Record<string, unknown | null | undefined>>(
    items: ReadonlyArray<T | null | undefined> | null | undefined,
): T[] => {
    if (!items) {
        return [];
    }

    return items.filter((item) => {
        if (!item) {
            return false;
        }

        return Object.values(item).some((value) => !!value);
    }) as T[];
};
