import type { ComponentType, ReactNode } from 'react';
import { useEffect } from 'react';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { c } from 'ttag';
import { classnames } from '@protonme/ui/helpers/classnames';
import { getLegacyBackgroundClasses } from '../Section/backgrounds';

export type Props = {
    icon?: ComponentType<{ className?: string }>;
    backgroundColor?: string;
    show: boolean;
    content: string | ReactNode;
    onClose?: () => void;
    onTimeOver?: () => void;
    timeout?: number;
};

export const Notification = ({
    backgroundColor = 'white',
    icon: Icon,
    show,
    content,
    // Disabled because we want an empty function as fallback to reduce redundant code
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClose = () => {},
    onTimeOver,
    timeout = 15_000,
}: Props) => {
    const showState = show;
    useEffect(() => {
        if (showState) {
            const timer = setTimeout(() => {
                onTimeOver && onTimeOver();
            }, timeout);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [onTimeOver, show, showState, timeout]);

    if (show) {
        return (
            <div
                className={classnames(
                    getLegacyBackgroundClasses(backgroundColor),
                    showState ? 'flex' : 'hidden',
                    'fixed bottom-12 z-notification w-3/4 items-start justify-between rounded-lg p-6 shadow-l md:left-12 md:w-1/2 xl:left-24 xl:w-1/3',
                )}
                role="status"
                aria-live="polite"
                data-test="notification"
            >
                {Icon && (
                    <div className="w-fit rounded-full bg-purple-50 p-4">
                        <Icon className="h-4 w-4" />
                    </div>
                )}
                <div className={classnames('flex-1', Icon ? 'px-6' : 'pr-6')}>{content}</div>
                <button onClick={onClose} data-test="close">
                    <span className="sr-only">{c('Action').t`Close notification`}</span>
                    <span aria-hidden="true">
                        <XMarkIcon className="h-4 w-4" />
                    </span>
                </button>
            </div>
        );
    }
    return null;
};
