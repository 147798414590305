export type Websites =
    | 'proton-me'
    | 'proton-me-blog'
    | 'proton-me-support'
    | 'protonvpn-com'
    | 'protonvpn-com-blog'
    | 'protonvpn-com-support'
    | null;

export const getWebsite = (url: URL, project: string | undefined): Websites => {
    if (url.origin === 'https://proton.me') {
        return ('proton-me' + (project ? `-${project}` : '')) as Websites;
    }

    if (url.origin === 'https://protonvpn.com') {
        return ('protonvpn-com' + (project ? `-${project}` : '')) as Websites;
    }

    return null;
};
