// It's a brute copy of `../../constants.js` but there is no way importing it in a compatible way for Astro
import type {Breakpoint} from "../types/image";

export const screens = {
    sm: '40em', // 640px
    md: '48em', // 768px
    lg: '64em', // 1024px
    xl: '80em', // 1280px
    '2xl': '96em', // 1536px
    '3xl': '115em', // 1840px
    '4xl': '120.0625em', // 1921px and higher
};

export const cloudinaryOptionPlaces = ['/images/', '/image/upload/'];
export const reduction = 0.3;
export const density = 2;
export const nbOfBreakpoints = 5;

export const getScreenSize = (breakpoint: Breakpoint) =>
    Number(screens[breakpoint].replace(/[^\d.]/g, '')) * 16;

// It's important to have sizes sorted, Object.keys almost always keeps definition order but let's be sure
export const screenSizes = (Object.keys(screens) as Breakpoint[]).sort(
    (a, b) => getScreenSize(a) - getScreenSize(b),
);

export const getMaxedDimensions = (
    dimensions: { width: number; height: number },
    maxWidth: number | Breakpoint = '2xl',
) => {
    const maxWidthNumber = typeof maxWidth === 'string' ? getScreenSize(maxWidth) : maxWidth;

    return maxWidthNumber > dimensions.width
        ? { width: dimensions.width, height: dimensions.height }
        : {
            width: maxWidthNumber,
            height: Math.round((maxWidthNumber * dimensions.height) / dimensions.width),
        };
};