import { fetchHelper } from '../../helpers/fetchHelper';
import type { SlimAPI_PaymentsPlans } from './types';

/**
 * @api /api/payments/v4/plans
 * @description Proton Slim API response containing all paid plans.
 * @docs https://protonmail.gitlab-pages.protontech.ch/Slim-API/payments/#tag/Plans/operation/get_payments-v4-plans
 */
export const getPaymentsPlans = async () => {
    const response = await fetchHelper('/payments/v4/plans');

    // in case of 4xx
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    const json: SlimAPI_PaymentsPlans = await response.json();
    return json;
};
