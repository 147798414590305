import { fetchHelper } from '../../helpers/fetchHelper';
import type { SlimAPI_PaymentsPlansDefault } from './types';

/**
 * @api /api/payments/v4/plans/default
 * @description Proton Slim API response containing the free plan.
 * @docs https://protonmail.gitlab-pages.protontech.ch/Slim-API/payments/#tag/Plans/operation/get_payments-v4-plans-default
 */
export const getPaymentsPlansDefault = async () => {
    const response = await fetchHelper('/payments/v4/plans/default');

    // in case of 4xx
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    const json: SlimAPI_PaymentsPlansDefault = await response.json();
    return json;
};
