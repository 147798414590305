export const isBlog = (link = ''): boolean => {
    return /blog|news|author/i.test(link);
};

export const isSupportWP = (link = ''): boolean => {
    return (
        /support/i.test(link) &&
        !/support(\/(mail|calendar|drive|pass|bridge|account|business|contact|abuse))?\/?$/i.test(
            link,
        )
    );
};

export const isWP = (link = ''): boolean => {
    return isBlog(link) || isSupportWP(link);
};
