import type { FilledLinkToWebField, LinkField } from '@prismicio/client';
import type { CtaNode } from '../types/prismic';
import type { CtaButton, LinkValue } from '../types/return';
import { parseSelect } from './parseSelect';
import { parseString } from './parseString';

export const targetValues = ['_blank', '_self'] as const;

export const parseLink = (field: LinkField | null | undefined): LinkValue | undefined => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (!(field as any)?.url) {
        return;
    }

    const typedField = field as FilledLinkToWebField;

    return {
        url: typedField.url,
        target: (targetValues as readonly string[]).includes(typedField.target || '')
            ? (typedField.target as (typeof targetValues)[number])
            : undefined,
    };
};

export const ctaButtonTypes = [
    'primary',
    'primary outlined',
    'secondary',
    'tertiary',
    'primary-b2b',
    'secondary-b2b',
    'tertiary-b2b',
    'auto',
    'cta',
    'download',
    'downloadfallback',
    'google play',
    'apple app store',
    'download bridge',
    'mail',
    'calendar',
    'drive',
    'vpn',
    'pass',
    'wallet',
    'green',
] as const;

export const parseCta = (node: CtaNode): CtaButton => {
    return {
        link: parseLink(node.button_link)?.url,
        label: parseString(node.button_label),
        type: parseSelect(node.button_type, ctaButtonTypes, 'primary'),
    };
};
