import { fetchHelper } from '../../helpers/fetchHelper';
import type {
    SlimAPI_CoreReportsForm,
    SlimAPI_CoreReportsFormFormIDs,
    SlimAPI_CoreReportsFormPortalIDs,
    SlimAPI_CoreReportsFormRequestBody,
} from './types';

/**
 * @api /api/core/v4/reports/form/{portal_id}/{form_id}
 * @description Proton Slim API to send special forms, e.g. "Contact Sales" form.
 * @docs https://protonmail.gitlab-pages.protontech.ch/Slim-API/core/#tag/Reports/operation/post_core-%7B_version%7D-reports-form-%7Bportal_id%7D-%7Bform_id%7D
 */
export const postCoreReportsForm = async (
    portal_id: SlimAPI_CoreReportsFormPortalIDs,
    form_id: SlimAPI_CoreReportsFormFormIDs,
    body: SlimAPI_CoreReportsFormRequestBody,
) => {
    const response = await fetchHelper(
        `/core/v4/reports/form/${portal_id}/${form_id}`,
        {
            method: 'POST',
            body: JSON.stringify(body),
        },
    );

    // in case of 4xx
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    const json: SlimAPI_CoreReportsForm = await response.json();
    return json;
};
