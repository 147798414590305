// This is a helper component to safely load children component with a defined delay
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';

type Props = {
    children: ReactElement;
    timeout: number;
};

const Delay = ({ children, timeout }: Props) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, timeout);

        return () => {
            clearTimeout(timer);
        };
    }, [timeout]);

    if (!timeout) {
        return children;
    }

    if (!isVisible) {
        return null;
    }

    return children;
};

export default Delay;
