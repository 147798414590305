import type { GatsbyBrowser } from 'gatsby';
import PageProviders from '../../containers/PageProviders';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
    element,
    props: { location, pageContext },
}) => {
    return (
        <PageProviders location={location} pageContext={pageContext}>
            {element}
        </PageProviders>
    );
};
