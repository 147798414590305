export const EXPERIMENT_CODES = [
    'PricingPageFreeModal',
    'SingleSignupPage',
    'VpnIntroPricing',
    'InnovativePricing',
    'MailPricingCardJune24',
    'MailHomePageJuly24',
    'PassPricingPage',
    'VPNDeals2024',
    'TestRelease',
    'TestExperiment',
] as const;

export type ExperimentCode = (typeof EXPERIMENT_CODES)[number];

export type ExperimentRecord = Partial<Record<ExperimentCode, string>>;
