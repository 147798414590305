import { type SelectField } from '@prismicio/client';

export const parseSelect = <T, D extends T | undefined>(
    field: SelectField | null | undefined,
    possibleValues: readonly T[],
    defaultValue: D,
    caseSensitive = false,
): T | D => {
    if (!field) {
        return defaultValue;
    }

    const values = caseSensitive
        ? (possibleValues as readonly string[])
        : possibleValues.map((value) => String(value).toLowerCase());

    const fieldValue = caseSensitive ? field : field.toLowerCase();

    if (!values.includes(fieldValue)) {
        return defaultValue;
    }

    return fieldValue as T;
};
