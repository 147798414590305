import { useState } from 'react';
import { useUADetails } from '../../../hooks/useUADetails';
import { useZendeskUnit } from '../hooks/useZendeskUnit';
import Delay from './Delay';

type Props = {
    locale?: string;
    url?: string;
};

const Zendesk = ({ locale, url }: Props) => {
    // Set width/height of iframe when chat is opened or closed
    const OPENED_STYLE = {
        width: '374px',
        height: '572px',
    };
    const CLOSED_STYLE = {
        width: '144px',
        height: '70px',
    };
    const [iframeStyle, setIframeStyle] = useState(CLOSED_STYLE);

    // Ensure Zendesk is only returned if available and on desktop devices
    const unit = useZendeskUnit();
    const { os } = useUADetails();
    const isMobile = !!os?.name && ['Android', 'iOS'].includes(os.name);

    if (!unit || isMobile) {
        return null;
    }

    // Overwrite & update Zendesk widget
    const iframeRef = (ref: HTMLIFrameElement) => {
        (async () => {
            // Abort if no ref is available
            if (ref === null || ref.contentWindow === null) {
                return;
            }

            // Wait for Zendesk widget to connect
            const isZendeskReady = new Promise((resolve) => {
                ref.contentWindow?.addEventListener(
                    'message',
                    (event) => {
                        if (event.data === 'zendesk:connected') {
                            resolve(true);
                        }
                    },
                    false,
                );
            });
            await isZendeskReady;

            // Close Zendesk widget initally
            ref.contentWindow.zE?.('webWidget', 'close');

            // Update Zendesk widget settings
            ref.contentWindow.zE?.('webWidget', 'setLocale', locale);
            ref.contentWindow.zE?.('webWidget', 'updatePath', {
                url: url,
            });

            ref.contentWindow.zE?.('webWidget:on', 'userEvent', (event: { action: string }) => {
                if (event.action === 'Web Widget Opened') {
                    setIframeStyle(OPENED_STYLE);
                }
                if (event.action === 'Web Widget Minimised') {
                    setIframeStyle(CLOSED_STYLE);
                }
            });
        })().catch((error) => {
            console.error('Zendesk:', error);
        });
    };

    return (
        <div id="zendesk">
            <Delay timeout={unit.widgetApperanceDelay}>
                <iframe
                    title="Zendesk"
                    src={unit.iframeUrl}
                    ref={iframeRef}
                    className="fixed bottom-0 right-0 z-zendesk max-h-full w-80 max-w-full"
                    style={iframeStyle}
                    sandbox="allow-scripts allow-same-origin allow-popups allow-forms"
                />
            </Delay>
        </div>
    );
};

export default Zendesk;
