"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mailBridgeSupportPath = void 0;
exports.fixUrl = fixUrl;
exports.mailBridgeSupportPath = /\/support\/mail\/bridge(\?ref=.+)?\/?$/;
function fixUrl(url) {
    if (exports.mailBridgeSupportPath.test(url))
        return url.replace('support/mail/bridge', 'support/bridge');
    return url;
}
