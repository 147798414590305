import type { GraphqlPrismicTitle } from '../../types/graphql';
import type { HtmlHeading, TitleContent, TrustedHtml } from '../../types/return';
import { removeTag, titleMapping } from '../parseTitle';
import { parseTrustedHtml } from '../parseTrustedHtml';
import { hasValue } from './hasValue';

const headingRegex = /^<(h[1-6])>/;

export const parseGraphqlTitle = (
    node: GraphqlPrismicTitle | null | undefined,
    limits: HtmlHeading[],
    defaultLevel: HtmlHeading,
): TitleContent | undefined => {
    if (!node) {
        return undefined;
    }
    if (node.html && hasValue(node.html)) {
        const content = removeTag(node.html);
        if (node.type) {
            const htmlLevel = titleMapping[node.type];
            if (limits.includes(htmlLevel)) {
                return {
                    tag: htmlLevel,
                    content: parseTrustedHtml(content, undefined) as TrustedHtml,
                    text: node.text || content,
                };
            }
        }
        const result = headingRegex.exec(node.html);
        if (result !== null) {
            const htmlLevel = result[1] as HtmlHeading;
            if (limits.includes(htmlLevel)) {
                return {
                    tag: htmlLevel,
                    content: parseTrustedHtml(content, undefined) as TrustedHtml,
                    text: node.text || content,
                };
            }
        }
        return {
            tag: defaultLevel,
            content: parseTrustedHtml(content, undefined) as TrustedHtml,
            text: node.text || content,
        };
    }
    if (node.text) {
        return {
            tag: defaultLevel,
            content: parseTrustedHtml(node.text, undefined) as TrustedHtml,
            text: node.text,
        };
    }
    return undefined;
};
