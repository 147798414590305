import { useEffect, useState } from 'react';
import FlagIcon from '@heroicons/react/24/solid/FlagIcon';
import { defaultLocale, isLocaleCode } from '@protonme/localization';
import { isWP } from '@protonme/routing';
import { c } from 'ttag';
import { CookieSameSiteAttribute, setCookie, getCookie } from '../../helpers/cookies';
import { hasValue } from '../../helpers/hasValue';
import { useCookie } from '../../hooks/useCookie';
import { changeCurrentLocale } from '../../modules/framework/changeCurrentLocale';
import { useFramework } from '../../modules/framework/useFramework';
import { Notification } from '../Notification/Notification';

export interface Props {
    enableSwitchLanguagePrompt: boolean;
}

export const PREFERRED_LANGUAGE_COOKIE = 'PreferredLanguage';
const SESSION_LANGUAGE_COOKIE = 'LangMessage';
const SHOULD_REDIRECT_TO_PREFERRED_LANG = true;

export const getBrowserLanguage = () => {
    if (typeof navigator === `undefined`) {
        return null;
    }
    // we need to split by , and ; to cover .split(/,|;/)
    // example: en-CA,en;q=0.8,en-US;q=0.6,de-DE;q=0.4,de;q=0.2
    const lang_raw = navigator && navigator.language && navigator.language.split(/,|;/)[0];
    const lang = lang_raw ? lang_raw.toLowerCase() : '';
    // check if we have exact match
    if (isLocaleCode(lang)) {
        return lang;
    }
    // split it by -|_ 0 is language, 1 can be script/region, 2 can be region (if has script)
    const lang_region = lang.split(/_|-/)[0];
    // check if we have just region
    if (isLocaleCode(lang_region)) {
        return lang_region;
    }
    // check if is special case pt-br (we return this even for just pt)
    if (lang_region === 'pt') {
        return 'pt-br';
    }
    return null;
};

export const setPreferredLanguage = (lang: string | undefined) => {
    const d = new Date();
    d.setTime(d.getTime() + 100 * 365 * 24 * 60 * 60 * 1000);
    setCookie({
        cookieName: PREFERRED_LANGUAGE_COOKIE,
        cookieValue: lang,
        expirationDate: d.toUTCString(),
        path: '/',
        samesite: CookieSameSiteAttribute.Lax,
        secure: true,
    });
};

export const setLanguageMessageCookie = () => {
    setCookie({
        cookieName: SESSION_LANGUAGE_COOKIE,
        cookieValue: '1',
        path: '/',
        samesite: CookieSameSiteAttribute.Lax,
        secure: true,
    });
};

export const PreferredLanguage = ({ enableSwitchLanguagePrompt = true }: Props) => {
    const {
        currentUrl,
        routing: { navigate },
    } = useFramework();
    const [preferredLocale] = useCookie(PREFERRED_LANGUAGE_COOKIE);
    const browserLocale = getBrowserLanguage();
    const [showNotification, setShowNotification] = useState(false);
    const [showWPNotification, setShowWPNotification] = useState(
        isWP(currentUrl.pathname) &&
            currentUrl.locale !== defaultLocale.code &&
            hasValue(preferredLocale),
    );

    const seenMessage = () => {
        setLanguageMessageCookie();
        setShowNotification(false);
    };

    const handlePreferredLanguage = (browserLocale: string) => {
        changeCurrentLocale(currentUrl, navigate, browserLocale, 'l10n_discoverybanner');
    };

    const languageLink = (languageClickName: string) => {
        return (
            <span
                role="button"
                className="cursor-pointer text-purple-500 underline"
                onClick={() => handlePreferredLanguage(browserLocale as string)}
                onKeyDown={() => handlePreferredLanguage(browserLocale as string)}
                tabIndex={0}
                aria-pressed="false"
            >
                {languageClickName}
            </span>
        );
    };

    const localizedMessages: Record<string, JSX.Element> = {
        en: <>To see this page in English, click {languageLink('here')}.</>,
        de: <>Um diese Seite auf Deutsch zu sehen, klicken Sie {languageLink('hier')}.</>,
        'es-es': <>Para ver esta página en español, haz clic {languageLink('aquí')}.</>,
        fr: <>Pour voir cette page en français, cliquez {languageLink('ici')}.</>,
        ru: <>Чтобы увидеть эту страницу на русском языке, нажмите {languageLink('здесь')}.</>,
        it: <>Per vedere questa pagina in italiano, clicca {languageLink('qui')}.</>,
        pl: <>Aby zobaczyć tę stronę w języku polskim, kliknij {languageLink('tutaj')}.</>,
        nl: <>Om deze pagina in het Nederlands te zien, klik {languageLink('hier')}.</>,
        'pt-br': <>Para ver esta página em português, clique {languageLink('aqui')}.</>,
        es: <>Para ver esta página en español, haga clic {languageLink('aquí')}.</>,
        tr: <>Bu sayfayı Türkçe olarak görüntülemek için {languageLink('buraya')} tıklayın.</>,
        cs: <>Pro zobrazení této stránky v češtině klikněte {languageLink('zde')}</>,
        da: <>For at se denne side på dansk, klik {languageLink('her')}</>,
        ro: <>Pentru a vedea această pagină în română, faceți clic {languageLink('aici')}</>,
        pt: <>Para ver esta página em português de Portugal, clique {languageLink('aqui')}</>,
        fi: <>Nähdäksesi tämän sivun suomeksi, klikkaa {languageLink('tästä')}</>,
        sv: <>För att se denna sida på svenska, klicka {languageLink('här')}</>,
    };

    const messageContent =
        (browserLocale && hasValue(browserLocale) && localizedMessages[browserLocale]) || null;

    useEffect(() => {
        if (!enableSwitchLanguagePrompt) return;
        // when we have preferred currentUrl.locale we redirect to it, no redirect for WP
        // no redirects for !enable_switch_language_prompt
        // check if is not already current and we have it
        if (
            preferredLocale &&
            preferredLocale !== currentUrl.locale &&
            isLocaleCode(preferredLocale) &&
            !isWP(currentUrl.pathname)
        ) {
            SHOULD_REDIRECT_TO_PREFERRED_LANG &&
                changeCurrentLocale(currentUrl, navigate, preferredLocale);
        }

        // check if conditions for dispalying message are met
        const isMessage = (currentLocale: string, preferredLocale?: string) => {
            const browserLanguage = getBrowserLanguage();
            // message was seen
            if (getCookie(SESSION_LANGUAGE_COOKIE)) return false;
            // visitor already has preferred currentUrl.locale set

            if (preferredLocale && isLocaleCode(preferredLocale)) return false;

            // we display message only if there is no cookie language, and browser lang is different then landing page lang and we need to have the browser language
            if (hasValue(browserLanguage) && browserLanguage !== currentLocale) return true;

            return false;
        };

        setShowNotification(isMessage(currentUrl.locale, preferredLocale));
    }, [currentUrl.locale, currentUrl.pathname, enableSwitchLanguagePrompt, preferredLocale]);

    if (!enableSwitchLanguagePrompt) {
        return null;
    }

    if (isWP(currentUrl.pathname)) {
        return (
            <Notification
                show={showWPNotification}
                icon={FlagIcon}
                content={c('OnlyEnglishPageMessage')
                    .t`We are sorry! This page is available only in English.`}
                onClose={() => setShowWPNotification(false)}
                onTimeOver={() => setShowWPNotification(false)}
            />
        );
    }
    return (
        <Notification
            show={showNotification}
            content={messageContent}
            onClose={() => seenMessage()}
            onTimeOver={() => seenMessage()}
            timeout={30_000}
        />
    );
};
