import { useEffect, useState } from 'react';
import { getCookie } from '../helpers/cookies';
import { useFramework } from '../modules/framework/useFramework';

export type TrafficType = 'direct' | 'organic' | 'paid';

// This list is obviously doomed to be obsolete, don't hesitate to update
const mostPopularSearchEngineDomains = [
    'google',
    'bing',
    'yahoo',
    'baidu',
    'yandex',
    'duckduckgo',
    'ask.com',
    'ecosia',
];

// Very basic logic, don't hesitate to extend it
const getTrafficType = (url: URL) => {
    const visitorId = getCookie('visitorId') || url.searchParams.get('visitorId');

    if (visitorId) {
        return 'paid';
    }

    const refferrer = window.document.referrer;

    if (refferrer) {
        const refUrl = new URL(refferrer);
        const domainMatch = mostPopularSearchEngineDomains.some((domain) =>
            refUrl.hostname.includes(domain),
        );
        if (domainMatch) {
            return 'organic';
        }
    }

    return 'direct';
};

export const useTrafficType = (): TrafficType | undefined => {
    const { currentUrl } = useFramework();
    const [trafficType, setTrafficType] = useState<TrafficType>();

    useEffect(
        () => {
            setTrafficType(getTrafficType(currentUrl));
        },
        // No dependencies, once set, we keep it for the session
        // On Astro, we may want to create some session storage to maintain the initial type
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return trafficType;
};
