import { useEffect, useState } from 'react';
import { UAParser } from 'ua-parser-js';
// @ts-ignore
import { Bots } from 'ua-parser-js/extensions';
// @ts-ignore
import { OS } from 'ua-parser-js/enums';
import { simplify } from '../helpers/simplify';

export interface UseUADetailsState {
    os?: UAParser.IOS & { distribution: string | undefined; };
    browser?: UAParser.IBrowser;
    engine?: UAParser.IEngine;
    device?: UAParser.IDevice;
}

export const getOsLabel = (os: string | undefined) => {
    // The OS string we have is not necessarily the one we need to display
    switch (simplify(os)) {
        case 'windows':
            return OS.WINDOWS;
        case 'macos':
            return OS.MACOS;
        case 'linux':
            return OS.LINUX;
        case 'android':
            return OS.ANDROID;
        case 'ios':
            return OS.IOS;
        default:
            return os;
    }
};

const getOSName = (os: UseUADetailsState['os']) => {
    if (os?.name && [
        OS.ARCH,
        OS.DEBIAN,
        OS.CENTOS,
        OS.FEDORA,
        OS.MANDRIVA,
        OS.MINT,
        OS.RASPBIAN,
        OS.REDHAT,
        OS.UBUNTU,
    ].includes(os.name)) {
        // Would prefer returning an object, but UAParser.IOS embed some functions, such as is().
        os.distribution = os.name;
        os.name = OS.LINUX;
    }

    return os;
};

export const useUADetails = (skip: boolean = false) => {
    const [uaDetails, setUADetails] = useState<UseUADetailsState>({
        os: undefined,
        browser: undefined,
        engine: undefined,
        device: undefined,
    });

    useEffect(() => {
        if (skip) return undefined;

        const getUADetails = async () => {
            const parser = new UAParser(Bots);
            const result = await parser.getResult().withClientHints();
            const { os, browser, engine, device } = result;

            setUADetails({
                os: getOSName(os as UseUADetailsState['os']),
                browser,
                engine,
                device: device.type
                    ? (device as UseUADetailsState['device'])
                    : ({ type: 'desktop' } as unknown as UseUADetailsState['device']),
            });
        };

        void getUADetails();
    }, [skip]);

    return uaDetails;
};
