import { xPmAppversion } from './x-pm-appversion';

export const fetchHelper: typeof fetch = async (input, init) => {
    // ensure that ProxyAgent is only imported and set during SSR
    let dispatcher;
    if (typeof window === 'undefined' && process.env.http_proxy) {
        const { ProxyAgent } = await import(/* webpackIgnore: true */ 'undici');
        dispatcher = new ProxyAgent(process.env.http_proxy);
    }

    const inputHref = input.toString();
    const url = new URL(
        inputHref.includes('https://') ? inputHref : `/api${inputHref}`,
        process.env.SLIM_API,
    );

    return fetch(url, {
        ...init,
        headers: {
            ...init?.headers,
            accept: 'application/vnd.protonmail.v1+json',
            ...(!(init?.body instanceof FormData) && {
                // content-type will be set automatically if body is form-data
                'content-type': 'application/json;charset=utf-8',
            }),
            'x-pm-appversion': xPmAppversion,
        },
        credentials: 'include',
        // @ts-ignore
        dispatcher,
    });
};
