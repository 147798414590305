import { type ReactNode, createContext, useMemo } from 'react';
import { type ProtonURL, getProtonUrl, getRelativePath } from '@protonme/routing';
import { UnleashStartClient } from '../../feature-flags';
import { createClient } from '../../feature-flags/utils/createClient';
import { defaultFrameworkValue } from '../default';
import type { FrameworkInputValue, FrameworkValue } from '../types';

export const FrameworkContext = createContext<FrameworkValue>(defaultFrameworkValue);

export interface FrameworkProviderProps extends FrameworkInputValue {
    children: ReactNode;
}

export const FrameworkProvider = ({ children, ...value }: FrameworkProviderProps) => {
    const unleash = useMemo(() => createClient(process.env.UNLEASH_API || ''), []);

    // currentUrl when passed to framework provider is string
    // ONLY here we then transform it from string to ProtonURL and enforce ProtonURL string since we know it has to be a ProtonURL (aka CurrentURL).
    // undefined is at this point not an option. We may provide a fallback with e.g. getProtonUrl("/").
    // const protonUrl = (getProtonUrl(value.currentUrl) || getProtonUrl("/")) as ProtonURL;
    const currentUrl = getProtonUrl(getRelativePath(value.currentUrl)) as ProtonURL;
    return (
        <FrameworkContext.Provider value={{ ...value, currentUrl, unleash }}>
            <UnleashStartClient />
            {children}
        </FrameworkContext.Provider>
    );
};
