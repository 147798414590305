import type { Product } from '@protonme/apps';
import { getSanitizedUrl } from './helpers/getSanitizedUrl';
import type { Websites } from './helpers/getWebsite';
import { parseProtonUrl } from './helpers/parseProtonUrl';

export interface ProtonURL extends URL {
    /**
     * The url but without locale.
     * #### Examples:
     * ```txt
     * "/foobar"      instead of "/de/foobar"
     * "/blog/foobar" instead of "/blog/de/foobar"
     * ```
     */
    defaultUrl: URL;

    /**
     * The locale of the url.
     */
    locale: string;

    /**
     * The website project name to which the url is related to.
     * #### Examples:
     * ```txt
     * /foobar  => proton-me or protonvpn-com
     * /support => proton-me-support or protonvpn-com-support
     * /blog    => proton-me-blog or protonvpn-com-blog
     * https://proton.me/foobar      => proton-me
     * https://proton.me/blog        => proton-me-blog
     * https://protonvpn.com/support => protonvpn-com-support
     * ```
     */
    website: Websites;

    /**
     * The Proton product name to which the url is related to.
     * #### Examples:
     * ```txt
     * /mail                 => mail
     * /drive/security       => drive
     * /support/pass         => pass
     * /foobar               => null
     * https://protonvpn.com => vpn
     * ```
     */
    product: Product | null;

    /**
     * Shows if url belongs to current origin.
     * Not needed for CurrentURL. (always true)
     * #### Examples
     * ```txt
     * Building project for proton-me, proton-me-blog, proton-me-support:
     * https://proton.me          => true
     * https://proton.me/support  => true
     * https://protonvpn.com      => false
     * https://protonvpn.com/blog => false
     * https://foo.bar            => false
     * ```
     */
    isCurrentOrigin: boolean;

    /**
     * Shows if the url is a Proton B2B page (Proton for Business).
     * #### Examples:
     * ```txt
     * /business      => true
     * /business/pass => true
     * /foobar        => false
     * ```
     */
    isB2B: boolean;

    /**
     * Shows if the url is a Proton pricing page.
     * #### Examples:
     * ```txt
     * /pricing      => true
     * /mail/pricing => true
     * /foobar       => false
     * ```
     */
    isPricing: boolean;

    /**
     * Shows if the url is for downloading a file.
     * #### Examples:
     * ```txt
     * /static/xyz      => true
     * /files/xyz       => true
     * /download/xyz    => true
     * /foo/bar/xyz.exe => true
     * ```
     */
    isDownload: boolean;

    /**
     * Shows if the url is a preview url.
     * #### Examples:
     * ```txt
     * /preview => true
     * /foobar  => false
     * ```
     */
    isPreview: boolean;
}

export const getProtonUrl = (url: URL | Location | string): ProtonURL | undefined => {
    const sanitizedUrl = getSanitizedUrl(url);

    // proton urls
    // Test against "origin" and NOT "hostname" otherwise it will fail on TOR. (GS-2257)
    // This is because when we serve on TOR we search and replace "https://proton.me" with "https://protonmailrmez3lotccipshtkleegetolb73fuirgj7r4o4vfu7ozyd.onion/" in all files we serve.
    // See nginx configuration: https://gitlab.protontech.ch/kubernetes/stacks/appedge-apache/-/blob/master/proton.me/values.yaml?ref_type=heads#L15-19
    if (
        sanitizedUrl.origin === 'https://proton.me' ||
        sanitizedUrl.origin === 'https://protonvpn.com'
    ) {
        const protonUrlProperties = parseProtonUrl(sanitizedUrl);
        const protonUrl: ProtonURL = Object.assign(sanitizedUrl, protonUrlProperties);
        return protonUrl;
    }

    // external url
    return undefined;
};
