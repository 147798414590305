import { FALLBACK_ORIGIN } from './constants';

/**
 * Returns a relative pathname of the provided URL.
 * Url doesn't matter, it will just return a relative URL of it.
 * Example:
 * ```txt
 * https://foo.bar/aaa/bb/c => /aaa/bb/c
 * ```
 */
export const getRelativePath = (url: URL | Location | string) => {
    if (typeof url === 'string') {
        const newUrl = new URL(url, FALLBACK_ORIGIN);
        return newUrl.pathname + newUrl.search + newUrl.hash;
    }
    return url.pathname + url.search + url.hash;
};
