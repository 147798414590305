import type { Translations } from '@protonme/localization';
import { type ProtonURL, getProtonUrl, getRelativePath } from '@protonme/routing';
import { init } from 'ttag';
import { createClient } from '../../feature-flags/utils/createClient';
import { simpleNavigateFunction } from '../default';
import type { LocalizationContext } from '../types';
import { $frameworkStore, setFrameworkStore } from './FrameworkStore';

type CurrentUrl = Parameters<typeof getProtonUrl>['0'];

// In Astro build mode including a base (like blog and support)
// Current url is given by Astro without the base in the current path
// This function compute a clean ProtonURL and fixing that exception
const getAstroCurrentUrl = (currentUrl: CurrentUrl, base: string) => {
    let relativePath = getRelativePath(currentUrl);

    if (base && !relativePath.startsWith(`/${base}`)) {
        relativePath = `/${base}${relativePath}`;
    }

    return getProtonUrl(relativePath) as ProtonURL;
};

const routing = { navigate: simpleNavigateFunction };

export type ServerProps = {
    currentUrl: Parameters<typeof getProtonUrl>['0'];
    localization: LocalizationContext;
    translations: Translations;
    base: string;
    imageCDNEnabled?: boolean;
    cdnUrl?: string;
};

export const initFrameworkServer = ({
    currentUrl: inputCurrentUrl,
    localization,
    translations,
    base,
    imageCDNEnabled,
    cdnUrl,
}: ServerProps) => {
    const currentUrl = getAstroCurrentUrl(inputCurrentUrl, base);
    setFrameworkStore({
        currentUrl,
        routing,
        localization,
        unleash: undefined,
        imageCDNEnabled,
        cdnUrl,
    });
    init($frameworkStore.get().currentUrl.locale, translations);
    return { currentUrl, routing, localization };
};

export type ClientProps = ServerProps & {
    unleashApi: string;
};

export const initFrameworkClient = ({
    currentUrl: inputCurrentUrl,
    localization,
    translations,
    unleashApi,
    base,
}: ClientProps) => {
    // Ultra basic Unleash setup, but ensure a request to account and set Session-Id cookie
    const unleash = createClient(unleashApi);

    const currentUrl = getAstroCurrentUrl(inputCurrentUrl, base);

    init(currentUrl.locale, translations);

    return {
        currentUrl,
        routing,
        localization,
        unleash,
        imageCDNEnabled: undefined,
        cdnUrl: undefined,
    };
};
