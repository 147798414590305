import { PRODUCTS, type Product } from '@protonme/apps';
import type { Websites } from './getWebsite';

export const getProduct = (restParts: string[], website: Websites): Product | null => {
    // protonvpn.com urls are always vpn product
    if (website?.includes('protonvpn-com')) {
        return 'vpn';
    }

    if (restParts.length === 0) {
        return null;
    }

    // if any url part contains a product we return it
    return (
        PRODUCTS.find((product) =>
            // Use the mail product menu on the /email page for the mail AB test. The .replace can be removed when the test is over.
            restParts.some((part) => part.replace('email', 'mail') === product),
        ) || null
    );
};
