import type { GraphqlCtaNode, GraphqlPrismicLink } from '../../types/graphql';
import type { CtaButton, LinkValue } from '../../types/return';
import { ctaButtonTypes, targetValues } from '../parseLink';
import { parseGraphqlSelect } from './parseGraphqlSelect';
import { parseGraphqlString } from './parseGraphqlString';

export const parseGraphqlLink = (
    link: GraphqlPrismicLink | null | undefined,
): LinkValue | undefined => {
    const url = link?.document?.url || link?.url || undefined;

    if (!link || !url) {
        return undefined;
    }

    return {
        url,
        target: (targetValues as readonly string[]).includes(link.target || '')
            ? (link.target as (typeof targetValues)[number])
            : undefined,
    };
};

export const parseGraphqlCta = (node: GraphqlCtaNode): CtaButton => {
    let link: string | undefined = undefined;
    const linkFields = ['button_link', 'button_url', 'button_link_url'] as const;

    linkFields.forEach((field) => {
        if (node[field]) {
            link =
                typeof node[field] === 'string'
                    ? parseGraphqlString(node[field] as string)
                    : parseGraphqlLink(node[field] as GraphqlPrismicLink)?.url;
        }
    });

    return {
        link,
        label: parseGraphqlString(node.button_label),
        type: parseGraphqlSelect(node.button_type, ctaButtonTypes, 'primary'),
    };
};
