import type { ImageField } from '@prismicio/client';
import type { BreakpointImage, Breakpoint, ImageData, ImageProps } from '@protonme/ui/types/image';
import { getMaxedDimensions, screenSizes } from '@protonme/ui/helpers/imageHelpers';

export const parseImage = (
    field: ImageField | null | undefined,
    maxWidth: number | Breakpoint = '2xl',
): ImageData | undefined => {
    if (!field || !field.url) {
        return undefined;
    }

    const dimensions = getMaxedDimensions(field.dimensions, maxWidth);

    return {
        src: field.url,
        ...dimensions,
        alt: field.alt || undefined,
        provider: 'prismic',
    };
};

export const getImageProps = (
    mapping: { base?: ImageData } & BreakpointImage,
): ImageProps | undefined => {
    let result: ImageProps | undefined = undefined;
    if (mapping.base) {
        result = mapping.base;
    }
    screenSizes.forEach((breakpoint) => {
        if (!mapping[breakpoint]) {
            return;
        }
        if (result === undefined) {
            result = mapping[breakpoint];
            return;
        }
        result[breakpoint] = mapping[breakpoint];
    });
    return result;
};
