import { PRODUCTS } from '@protonme/apps';
import type { ProtonURL } from './getProtonUrl';

const availableProducts = PRODUCTS.filter((product) => product !== 'vpn');

// 🦕 TODO: Make usable for proton.me and protonvpn.com
export const getSignInLink = (url: ProtonURL | undefined) => {
    if (
        url?.product &&
        availableProducts.some((availableProduct) => availableProduct === url.product)
    ) {
        return `https://account.proton.me/${url.product}`;
    }

    return 'https://account.proton.me/login';
};
