import { protonLink } from '@protonme/routing';
import type { FrameworkValue, TrustedHtml } from '../types/return';

/**
 * Lets say it clearly: parsing HTML with regular expressions is a bad idea
 * But:
 * - Obvious lib to use would be cheerio and it weight 80kb which is a blocker
 * - We don't receive generic HTML, but only JS generated by Prismic which is a lot simpler
 *
 * In the same idea, this regex is way to simple for "real" html, but we're dealing with simple Prismic content
 */
const linkRegex = /<a +href="([^"]*)" *(target="(_\w+)")? *(rel="noopener noreferrer")? *>/g;

// List of transformations to apply to all Prismic strings
const mapping = {
    '&amp;nbsp;': '&nbsp;',
};

/**
 * Don't use this function directly in production code as much as possible
 * It's an internal tool to convert incoming Prismic source string into TrustedHtml
 * It's also use to convert some technical content such as non breakable space
 */
export const parseTrustedHtml = (
    value: string | null | undefined,
    context: FrameworkValue | undefined,
): TrustedHtml | undefined => {
    if (typeof value !== 'string') {
        return undefined;
    }

    let result = value;
    Object.entries(mapping).forEach(([from, to]) => (result = result.replaceAll(from, to)));

    if (!context) {
        return result as TrustedHtml;
    }

    // If someday, we can have that full backend and use cheerio

    // const $ = cheerio.load(result);

    // $('a').each((i, element) => {
    //     const link = protonLink(element.attribs as { href: string; target?: string }, context);
    //     element.attribs = { ...element.attribs, ...link };
    // });

    // return $('body').html() as TrustedHtml;

    result = result.replaceAll(linkRegex, (match, hrefMatch, _, targetMatch) => {
        // Cleanup some inconsistency in content
        const href = hrefMatch.replace(/\s/g, '');

        const target = ['_blank', '_self'].includes(targetMatch)
            ? (targetMatch as '_blank' | '_self')
            : undefined;

        let linkData: ReturnType<typeof protonLink>;
        try {
            linkData = protonLink({ href, target }, context);
        } catch (e) {
            linkData = { href: '#' };
        }

        let result = `<a href="${linkData.href}"`;

        if (linkData.target) {
            result += ` target="${linkData.target}"`;
        }
        if (linkData.rel) {
            result += ` rel="${linkData.rel}"`;
        }

        return result + '>';
    });

    return result as TrustedHtml;
};
