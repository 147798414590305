import { getUrlIdByOfferId } from './affiliates';

const TUNE_API = 'https://go.getproton.me/aff_c';

const TuneCall = (
    offerId: string,
    affiliateId: string,
    transactionId: string,
    search: string,
): null => {
    try {
        const urlParams = new URLSearchParams(search);
        urlParams.append('offer_id', offerId);
        urlParams.append('aff_id', affiliateId);
        getUrlIdByOfferId(offerId) && urlParams.append('url_id', getUrlIdByOfferId(offerId) || '');
        urlParams.append('ad_id', transactionId);
        const query = urlParams.toString();

        void fetch(`${TUNE_API}?${query}`, {
            headers: {},
            method: 'GET',
        });
    } catch (e) {
        console.error(e);
    }

    return null;
};
export default TuneCall;
