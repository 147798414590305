/*
 * Sets "x-pm-appversion" depending on git tag
 * If git tag is "v1.2.3+protonvpn-com" the xPmAppversion will be "web-static-protonvpncom@1.2.3"
 * Fallback is always "web-static@0.0.0"
 */

const versionApp = process.env.CI_COMMIT_TAG?.match(/^[vV](\d+)\.(\d+)\.(\d+)\+(.*)$/);

const app = versionApp ? `web-static-${versionApp[4].replace('-', '')}` : 'web-static';
const version = versionApp ? `${versionApp[1]}.${versionApp[2]}.${versionApp[3]}` : '0.0.0';

export const xPmAppversion = `${app}@${version}`;
