import { useCallback, useMemo, useState } from 'react';

const key = 'ref';

const readValue = () => {
    try {
        return window.localStorage.getItem(key) || undefined;
    } catch {
        return undefined;
    }
};

const setValue = (value: string | undefined) => {
    try {
        if (value === undefined) {
            window.localStorage.removeItem(key);
        } else {
            window.localStorage.setItem(key, value);
        }
    } catch {
        // abandon and forget
    }
};

export const useUrlWithRef = (inputUrl: URL) => {
    const [stateRef, setStateRef] = useState<string | undefined>();

    const url = useMemo(() => {
        const url = new URL(inputUrl.href); // copy
        setStateRef(url.searchParams.get('ref') || undefined);
        url.searchParams.delete('ref');
        return url;
    }, [inputUrl.href]);

    const linkHandler = useCallback(async () => {
        if (stateRef !== undefined) {
            setValue(stateRef);
        }
    }, [stateRef]);

    return { url, linkHandler };
};

export const consumeUrlRef = () => {
    const ref = readValue();
    setValue(undefined);
    return ref;
};

export const useUrlRef = () => {
    return useMemo(() => readValue(), []);
};
