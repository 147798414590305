import type { VariantType } from '../../types/variant';
import { VariantContext } from './VariantContext';

export interface VariantProviderProps {
    children: React.ReactNode;
    variant: VariantType;
}

export const VariantProvider = ({ variant, children }: VariantProviderProps) => (
    <VariantContext.Provider value={variant}>{children}</VariantContext.Provider>
);
