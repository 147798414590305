/* eslint-disable react-hooks/rules-of-hooks */
import { defaultLocale } from '@protonme/localization';
import type { Translations } from '@protonme/localization';
import ttag from './ttag';

const { useLocale, addLocale } = ttag;

export const init = (locale: string, translations: Translations) => {
    if (locale === defaultLocale.code) {
        useLocale(locale);
    } else if (locale && translations) {
        addLocale(locale, translations);
        useLocale(locale);
    }
};
