import { asHTML, asText, type TitleField } from '@prismicio/client';
import type { HtmlHeading, TitleContent, TrustedHtml } from '../types/return';
import { parseTrustedHtml } from './parseTrustedHtml';

export const titleMapping = {
    heading1: 'h1' as const,
    heading2: 'h2' as const,
    heading3: 'h3' as const,
    heading4: 'h4' as const,
    heading5: 'h5' as const,
    heading6: 'h6' as const,
};

const openRegex = /^<h[1-6]>/;
const closeRegex = /<\/h[1-6]>$/;

export const removeTag = (html: string) => html.replace(openRegex, '').replace(closeRegex, '');

export const parseTitle = (
    field: TitleField | null | undefined,
    limits: HtmlHeading[],
    defaultLevel: HtmlHeading,
): TitleContent | undefined => {
    if (!field?.[0] || !field[0].text) {
        return undefined;
    }

    asText;

    const html = removeTag(asHTML(field)) || undefined;
    const inputLevel = titleMapping[field[0].type];
    const level = limits.includes(inputLevel) ? inputLevel : defaultLevel;
    const text = asText(field);
    return { tag: level, content: parseTrustedHtml(html, undefined) as TrustedHtml, text };
};

/**
 * Helper meant for static content, testing and Storybook, not slice data!
 * If you deal with Prismic slice data, use `parseTitle`
 */
export const getTitle = (content: string, tag: HtmlHeading = 'h2'): TitleContent => ({
    tag,
    content: parseTrustedHtml(content, undefined) as TrustedHtml,
    text: content,
});
