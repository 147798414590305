import { fetchHelper } from '../../helpers/fetchHelper';
import type { SlimAPI_DataStats, SlimAPI_DataStatsRequestBody } from './types';

/**
 * @api /api/data/v1/stats
 * @description Proton Slim API response containing status if posting stat was successfull.
 * @docs https://protonmail.gitlab-pages.protontech.ch/Slim-API/data/#tag/Data/operation/post_data-v1-stats
 */
export const postDataStats = async (body: SlimAPI_DataStatsRequestBody) => {
    const response = await fetchHelper('/data/v1/stats', {
        method: 'POST',
        body: JSON.stringify(body),
    });

    // in case of 4xx
    if (!response.ok) {
        throw new Error(response.statusText);
    }

    const json: SlimAPI_DataStats = await response.json();
    return json;
};
